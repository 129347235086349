import { Routes, Route } from 'react-router-dom';
import AuthProvider from './services/AuthProvider';
import './App.css';
import LoginPage from './pages/Login';
import DashboardPage from './pages/Dashboard';
import PlantationListPage from './pages/plantation/Index';
import 'remixicon/fonts/remixicon.css';
import ProtectedRoute from './services/ProtectedRoute';
import DiseaseListingPage from './pages/diseases/Index';
import BlockListingPage from './pages/block/Index';
import ViewPlantationPage from './pages/plantation/View';
import CreatePlantationPage from './pages/plantation/Create';
import EditPlantationPage from './pages/plantation/Edit';
import ManageUserPage from './pages/users/Index';
import RoleListPage from './pages/role/Index';
import GeneratedQRPage from './pages/plantation/QRgenerator';
import DivisionListingPage from './pages/division/Index';
import SubBlockListingPage from './pages/subBlock/Index';
import { BlockIdProvider } from './services/BlockIdContext';
import PlantationByTypePage from './pages/plantation/PlantationByType';
import PlantationByDiseasePage from './pages/plantation/PlantationByDisease';
import ProgramCalendarPage from './pages/program/Index';
import ProgramPage from './pages/program/Index';
import ReportByDivisionPage from './pages/report/division/ReportByDivision';
import ReportByTypePage from './pages/report/clone/ReportByType';
import ReportByDiseasePage from './pages/report/disease/ReportByDisease';
import PublicViewPlantationPage from './pages/plantation/PublicView';
import ReportByBlockPage from './pages/report/division/ReportByBlock';
import ReportBySubBlockPage from './pages/report/division/ReportBySubBlock';
import ReportByEachDiseasePage from './pages/report/disease/ReportByEachDisease';
import ReportByFruitPage from './pages/report/fruit/ReportByFruit';
import ReportByEachFruitPage from './pages/report/fruit/ReportByEachFruit';
import CloneListingPage from './pages/clones/Index';
import PhaseListingPage from './pages/phases/Index';
import ReportByPhasePage from './pages/report/phase/ReportByPhase';
import ReportByEachPhasePage from './pages/report/phase/ReportByEachPhase';


function App() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="public/plantation/view/:plantationId" element={<PublicViewPlantationPage />} />
      <Route
        exact
        path='/'
        element={
          <AuthProvider>
            <ProtectedRoute />
          </AuthProvider>
        }
      >
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/plantation" element={<BlockIdProvider><PlantationListPage /></BlockIdProvider>} />
        <Route path="/plantation/create/:subBlockId" element={<CreatePlantationPage />} />
        <Route path="/plantation/QRgenerator" element={<GeneratedQRPage />} />
        <Route path="/plantation/view/:plantationId" element={<ViewPlantationPage />} />
        <Route path="/plantation/edit/:plantationId" element={<EditPlantationPage />} />
        <Route path="/plantation/clone/:type/:subBlockId" element={<PlantationByTypePage />} />
        <Route path="/plantation/disease/:disease/:subBlockId" element={<PlantationByDiseasePage />} />
        <Route path="/disease" element={<DiseaseListingPage />} />
        <Route path="/block" element={<BlockListingPage />} />
        <Route path="/block/sub-block/:blockId" element={<BlockIdProvider><SubBlockListingPage /></BlockIdProvider>} />
        <Route path="/user" element={<ManageUserPage />} />
        <Route path="/role" element={<RoleListPage />} />
        <Route path="/division" element={<DivisionListingPage />} />
        <Route path="/program" element={<ProgramCalendarPage />} />
        <Route path="/programCalendar" element={<ProgramPage />} />
        <Route path="/report/division" element={<ReportByDivisionPage />} />
        <Route path="/report/division/block/:divisionId" element={<ReportByBlockPage />} />
        <Route path="/report/division/block/sub-block/:blockId" element={<ReportBySubBlockPage />} />
        <Route path="/report/clone" element={<ReportByTypePage />} />
        <Route path="/report/disease" element={<ReportByDiseasePage />} />
        <Route path="/report/disease/:diseaseId" element={<ReportByEachDiseasePage />} />
        <Route path="/report/fruit" element={<ReportByFruitPage />} />
        <Route path="/report/fruit/:cloneId" element={<ReportByEachFruitPage />} />
        <Route path="/clone" element={<CloneListingPage />} />
        <Route path="/phase" element={<PhaseListingPage />} />
        <Route path="/report/phase" element={<ReportByPhasePage />} />
        <Route path="/report/phase/:phaseId" element={<ReportByEachPhasePage />} />
      </Route>
    </Routes>
  );
}

export default App;
