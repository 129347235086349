import LayoutPage from '../layouts/base';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencil, faQrcode, faRemove } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { API_BASE_URL, SABAHEBAT_DEV_URL } from '../../constants/ApiConfig';
import { Button, InputGroup, Modal, Table } from 'react-bootstrap';
import SuccessModal from '../components/success-modal';
import * as XLSX from 'xlsx';
import axios from 'axios';
import QRCodeModal from '../components/qrcode-modal';
import QRCodeNew from "qrcode";
import { useAuth } from '../../services/AuthProvider';
import { useBlockId } from '../../services/BlockIdContext';

function PlantationListPage() {

    const { user, hasPermission } = useAuth();

    const navigate = useNavigate();

    const canManagePlantation = hasPermission('manage-plantation');
    const canCreatePlantation = hasPermission('create-plantation');
    const canEditPlantation = hasPermission('edit-plantation');
    const canDeletePlantation = hasPermission('delete-plantation');

    const itemsPerPage = 10;
    const [selectedDivision, setSelectedDivision] = useState('');
    const [selectedBlock, setSelectedBlock] = useState('');
    const [selectedSubBlock, setSelectedSubBlock] = useState('');
    const [plantationData, setPlantationData] = useState([]);
    const [divisionData, setDivisionData] = useState([]);
    const [blockData, setBlockData] = useState([]);
    const [subBlockData, setSubBlockData] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showSuccessDeleteModal, setshowSuccessDeleteModal] = useState(false);
    const handleImportModal = () => setShowImportModal(true);
    const [showImportModal, setShowImportModal] = useState(false);
    const handleImportModalClose = () => setShowImportModal(false);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const { divisionId, blockId, subBlockId } = useBlockId();

    useEffect(() => {
        const fetchDivisionData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}division/index`);
                if (response.ok) {
                    const data = await response.json();
                    const divisions = data.divisions;
                    console.log(divisions);
                    setDivisionData(divisions)

                    if(divisionId) {
                        setSelectedDivision(divisionId);
                    } else {
                        if (divisions.length > 0) {
                            setSelectedDivision(divisions[0].id);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchDivisionData();
    }, [divisionId]);

    useEffect(() => {
        const fetchBlockData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}block/fetchBlockData/${selectedDivision}`);
                if (response.ok) {
                    const data = await response.json();
                    const blocks = data.blocks;
                    setBlockData(blocks)

                    if(blockId) {
                        setSelectedBlock(blockId);
                    } else {
                        if (blocks.length > 0) {
                            setSelectedBlock(blocks[0].id);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchBlockData();
    }, [selectedDivision]);

    useEffect(() => {
        const fetchSubBlockData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}subBlock/index/${selectedBlock}`);
                if (response.ok) {
                    const data = await response.json();
                    const subBlocks = data.subBlocks;
                    setSubBlockData(subBlocks)

                    if(subBlockId) {
                        setSelectedBlock(blockId);
                    } else {
                        if (subBlocks.length > 0) {
                            setSelectedSubBlock(subBlocks[0].id);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchSubBlockData();
    }, [selectedBlock]);

    useEffect(() => {
        if (selectedSubBlock !== '') {
            fetch(`${API_BASE_URL}plantation/index/${selectedSubBlock}?searchTerm=${searchTerm}`)
                .then((response) => response.json())
                .then((data) => {
                    const plantations = data.plantations;
                    console.log(plantations)
                    setPlantationData(plantations);

                    const filteredPlantations = filterData(plantations, searchTerm);
                    setFilteredData(filteredPlantations);
                })
                .catch((error) => {
                    console.error('Error fetching plantation data:', error);
                });
        }
    }, [selectedSubBlock, searchTerm]);

    const handleDivisionChange = (e) => {
        setSelectedDivision(e.target.value);
    };

    const handleBlockChange = (e) => {
        setSelectedBlock(e.target.value);
    };

    const handleSubBlockChange = (e) => {
        setSelectedSubBlock(e.target.value);
    };

    // Function to filter data based on search term
    const filterData = (data, term) => {
        return data.filter((plantation) =>
            Object.values(plantation).some(
                (value) =>
                    value && value.toString().toLowerCase().includes(term.toLowerCase())
            )
        );
    };


    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    const handleDeletePlantation = async (index) => {
        try {
            const response = await fetch(`${API_BASE_URL}plantation/destroy/${index}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status == 200) {
                setShowDeleteModal(false);
                setshowSuccessDeleteModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const handleConfirmDelete = (id) => {
        setDeleteIndex(id);
        setShowDeleteModal(true);
    };

    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const renderIndex = (index) => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const maxPagesToShow = 5;

    // Change page
    const paginate = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) {
            return;
        }
        setCurrentPage(pageNumber);
    };

    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    const totalEntries = plantationData.length;
    const startEntry = renderIndex(0);
    const endEntry = renderIndex(currentItems.length - 1);

    // Function to generate an array of page numbers to display
    const getPageNumbers = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, index) => start + index);
    };

    // Import Excel File
    const [importExcelData, setImportExcelData] = useState([]);

    const handleImportFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const binaryString = event.target.result;
            const workbook = XLSX.read(binaryString, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            setImportExcelData(data);
        };

        reader.readAsBinaryString(file);
    };

    const handleSubmitImportFile = async (event) => {
        event.preventDefault();
        console.log(importExcelData);

        try {
            const response = await fetch(`${API_BASE_URL}plantation/import/${selectedSubBlock}`, {
                method: 'POST',
                body: JSON.stringify(importExcelData),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status == 200) {
                setShowImportModal(false);
                setShowSuccessModal(true);
            } else {
                console.error('Failed to send data');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleExportToExcel = async (selectedSubBlock) => {
        try {
            const response = await axios.get(`${API_BASE_URL}plantation/index/${selectedSubBlock}`);
            const data = response.data;
            console.log(data);

            const plantations = data.plantations;

            if (plantations.length === 0) {
                console.error('No data to export');
                return;
            }

            const columnMapping = {
                plant_no: 'PLANT NO',
                division_name: 'DIVISION',
                block_name: 'BLOCK',
                sub_block_name: 'SUB-BLOCK',
                type: 'CLONE',
                year_planted: 'YEAR PLANTED',
                name: 'NAME',
                status: 'STATUS',
                latitude: 'LATITUDE',
                longitude: 'LONGITUDE',
                details: 'DETAILS',
                features: 'FEATURES',
                cares: 'CARES',
            };

            const selectedColumns = Object.keys(columnMapping);

            const filteredData = plantations.map((row) =>
                selectedColumns.reduce((acc, curr) => {
                    const newName = columnMapping[curr];
                    acc[newName] = row[curr];
                    return acc;
                }, {})
            );

            const worksheet = XLSX.utils.json_to_sheet(filteredData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

            const currentDate = new Date().toISOString().split('T')[0].replace(/-/g, '');
            const blockName = data.block_name;

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `Block_${blockName}_${currentDate}_export.xlsx`;
            link.click();
        } catch (error) {
            console.error('Error exporting data:', error);
        }
    };

    const [modalShow, setModalShow] = useState(false);
    const [plantationIndex, setPlantationIndex] = useState(null);
    const [plantationQRImage, setPlantationQRImage] = useState(null);
    const [qrCodeImageData, setQRCodeImageData] = useState('');

    const handleGenerateQRCode = async (plantationId) => {
        try {
            const canvas = document.createElement('canvas');
            const url = `${SABAHEBAT_DEV_URL}/plantation/edit/${plantationId}?source=qrscan`;
            const redirectUrl = encodeURIComponent(url);
            const viewUrl = `${SABAHEBAT_DEV_URL}/public/plantation/view/${plantationId}?source=qrscan`;
            const redirectViewUrl = encodeURIComponent(viewUrl);
            const loginUrl = `${SABAHEBAT_DEV_URL}/?redirectUrl=${redirectUrl}&redirectViewUrl=${redirectViewUrl}`;
            QRCodeNew.toCanvas(canvas, loginUrl);
            const qrCodeDataURL = canvas.toDataURL('image/png');

            setQRCodeImageData(qrCodeDataURL);
        } catch (error) {
            console.error('Error generating or saving QR code:', error);
        }
    };

    const openQRModalWithId = (id, qrImage) => {
        setPlantationIndex(id);
        setPlantationQRImage(qrImage);
        setModalShow(true);
        handleGenerateQRCode(id);
    };

    const handleQRModalClose = () => {
        setModalShow(false);
        setPlantationIndex(null);
        setPlantationQRImage(null);
    };

    const clearSearch = () => {
        setSearchTerm('');
    };

    return (
        <div>
            <LayoutPage>
                <div className='children'>
                    <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                        <h4 className="mt-3 pg_title">
                            LIST OF PLANTATIONS
                        </h4>
                        <div className="mr-3 row" style={{ width: "50%" }}>
                            <div className='col-4'>
                                <Form.Select
                                    onChange={handleDivisionChange}
                                    value={selectedDivision}
                                >
                                    {divisionData.map((division) => (
                                        <option key={division.id} value={division.id}>
                                            {division.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                            <div className='col-4'>
                                <Form.Select
                                    onChange={handleBlockChange}
                                    value={selectedBlock}
                                >
                                    {blockData.map((block) => (
                                        <option key={block.id} value={block.id}>
                                            Block {block.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                            <div className='col-4'>
                                <Form.Select
                                    onChange={handleSubBlockChange}
                                    value={selectedSubBlock}
                                >
                                    {subBlockData.map((subBlock) => (
                                        <option key={subBlock.id} value={subBlock.id}>
                                            {subBlock.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap listTopBar top_bar p-3 bold_poppins justify-content-between">
                        <div className="d-flex flex-wrap">
                            {canCreatePlantation &&
                                <button className="addZone mr-2" onClick={() => navigate(`/plantation/create/${selectedSubBlock}`)}>
                                    <FontAwesomeIcon className="icon mr-2" icon={faPlus} />
                                    Plantation
                                </button>
                            }
                            <div className="">
                                <InputGroup className="mr-3">
                                    <Form.Control
                                        placeholder="Search..."
                                        aria-label=""
                                        aria-describedby="basic-addon1"
                                        style={{ width: "200px" }}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        value={searchTerm}
                                    />
                                    {searchTerm && (
                                        <InputGroup.Text id="clear-search" onClick={clearSearch}>
                                            <FontAwesomeIcon icon={faRemove} className="icon edit-icon" />
                                        </InputGroup.Text>
                                    )}
                                </InputGroup>
                            </div>
                        </div>
                        {canManagePlantation &&
                            <div className='d-flex text-right'>
                                <button onClick={handleImportModal} className='import_btn' rel="noopener noreferrer">
                                    Import
                                </button>
                                <button onClick={() => handleExportToExcel(selectedSubBlock)} className='eksport_btn ml-2' target="_blank" rel="noopener noreferrer">
                                    Export
                                </button>
                            </div>
                        }
                    </div>

                    <div className='m-3 table-container table_responsive'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Plant No</th>
                                    <th>Division</th>
                                    <th>Block</th>
                                    <th>Sub-Block</th>
                                    <th>Clone</th>
                                    <th>Year Planted</th>
                                    <th>Latitude</th>
                                    <th>Longitude</th>
                                    {(canManagePlantation || canEditPlantation || canDeletePlantation) && <th className='text-center'>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.length > 0 ? (
                                    currentItems.map((plantation, index) => (
                                        <tr key={index}>
                                            <td>{renderIndex(index)}</td>
                                            <td>{plantation.plant_no || '-'}</td>
                                            <td>{plantation.division.name || '-'}</td>
                                            <td>{plantation.block_name || '-'}</td>
                                            <td>{plantation.sub_block_name || '-'}</td>
                                            <td>{plantation.clone_name || '-'}</td>
                                            <td>{plantation.year_planted || '-'}</td>
                                            <td>{plantation.latitude || '-'}</td>
                                            <td>{plantation.longitude || '-'}</td>
                                            {(canManagePlantation || canEditPlantation || canDeletePlantation) && <td className='text-center'>
                                                <div className="unwrap editdelete_icon">
                                                    {canManagePlantation && <FontAwesomeIcon icon={faQrcode} className="icon edit-icon" onClick={(e) => { e.stopPropagation(); openQRModalWithId(plantation.id, plantation.qr_image) }} />}
                                                    {canManagePlantation && <FontAwesomeIcon icon={faEye} className="icon edit-icon" onClick={() => navigate(`/plantation/view/${plantation.id}`)} />}
                                                    {canEditPlantation && <FontAwesomeIcon icon={faPencil} className="icon edit-icon" onClick={() => navigate(`/plantation/edit/${plantation.id}`)} />}
                                                    {canDeletePlantation && <FontAwesomeIcon icon={faTrash} className="icon delete-icon" onClick={(e) => { e.stopPropagation(); handleConfirmDelete(plantation.id); }} />}
                                                </div>
                                            </td>}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="10" className="text-center">No data found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                    <div className="d-flex flex-wrap-reverse">
                        <div className='col-md-6'>
                            <p className='pl-3' style={{ fontFamily: 'Poppins' }}>
                                Showing {startEntry} to {endEntry} of {totalEntries} entries
                            </p>
                        </div>

                        {/* Pagination component */}
                        <div className='col-md-6 pagination flex-wrap'>
                            <div>
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button onClick={() => paginate(currentPage - 1)} className="page-link">
                                        Previous
                                    </button>
                                </li>
                            </div>
                            <div className='d-flex flex-wrap'>
                                {getPageNumbers(startPage, endPage).map((page) => (
                                    <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                                        <button onClick={() => paginate(page)} className="page-link">
                                            {page}
                                        </button>
                                    </li>
                                ))}
                            </div>
                            <div >
                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                    <button onClick={() => paginate(currentPage + 1)} className="page-link">
                                        Next
                                    </button>
                                </li>
                            </div>
                        </div>

                    </div>
                </div>

                {/* Delete Modal */}
                <Modal className='modal_responsive' show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this plantation?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleCloseDeleteModal}>
                            Cancel
                        </Button>
                        <Button className="delete-button" onClick={() => handleDeletePlantation(deleteIndex)}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal/Popup for Confirmation Delete */}
                <SuccessModal
                    show={showSuccessDeleteModal}
                    onHide={closeSuccessModal}
                    title="Data Deleted"
                    modalBody={<p>Data was successfully deleted!</p>}
                    buttonText="OK"
                />

                {/* Modal Import File */}
                <Modal className='modal_import modal_responsive' show={showImportModal} onHide={handleImportModalClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Import Data</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmitImportFile}>
                            <Form.Group>
                                <Form.Label className="label-left">Upload Excel file</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="file"
                                        onChange={handleImportFileUpload}
                                        accept=".xlsx, .xls"
                                        name="fileInput"
                                        id='fileInput'
                                        aria-describedby='basic-addon3'
                                    />
                                </InputGroup>
                                <Form.Text className="text-muted">
                                    Please upload an Excel file. You can download an example file <a href="/files/example_block_data.xlsx" download>here</a>.
                                </Form.Text>
                            </Form.Group>
                            <Button variant="primary" type="submit" className="mt-4">
                                Import
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                {/* Modal/Popup for Success Message */}
                <SuccessModal
                    className="modal_responsive"
                    show={showSuccessModal}
                    onHide={closeSuccessModal}
                    title="Success"
                    modalBody={<p>Data successfully import!</p>}
                    buttonText="Close"
                />

                <QRCodeModal
                    show={modalShow}
                    id={plantationIndex}
                    onHide={handleQRModalClose}
                    qrCodeImageData={qrCodeImageData}
                />

            </LayoutPage>
        </div>
    )
}

export default PlantationListPage