import {
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer,
  Cell,
  Tooltip,
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
} from "recharts";

import GeoJSON from "ol/format/GeoJSON.js";
import "./map-pie-chart.css";
import React, { useState, useEffect, useRef } from "react";
import data_vector from "../../../assets/combined.json";
import chartsvg from "../../../assets/chart.svg";
import closetoggle from "../../../assets/close-toggle.svg";

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "end" : "start";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      {/* <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      /> */}
      {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
      {/* <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        // dx={-10}
        textAnchor={textAnchor}
        fill="#333"
      >
        <tspan>{`${value}`}</tspan>
        <tspan>{`(${(percent * 100).toFixed(2)}%)`}</tspan>
      </text> */}
      {/* <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor="middle"
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text> */}
    </g>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        style={{
          backgroundColor: "white",
          padding: "5px",
          border: "1px solid #ccc",
        }}
      >
        <p>
          {`${data.name}`}
          <br />
          {`${data.value} - ${((data.value / data.count) * 100).toFixed(2)}%`}
        </p>
      </div>
    );
  }

  return null;
};
const MapPieChart = ({ vectorLayer, map }) => {
  const [state, setState] = useState(null);
  const [stateBar, setStateBar] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [toolBartipOpen, setToolBartipOpen] = useState(false);
  const [pieChartData, setPieChartData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [dataCount, setDataCount] = useState(null);

  const pieChartRef = useRef(null);
  const pieResetRef = useRef(null);
  const toolRef = React.createRef();
  const cardRef = React.createRef();
  let [pieChartClicked, setpieChartClicked] = useState(false);
  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#AF19FF",
    "#FF195F",
    "#19FF3B",
    "#FF19EB",
    "#FF1919",
    "#4D19FF",
    "#19C2FF",
    "#19FFAE",
    "#E7FF19",
    "#FFCF19",
    "#FF8C19",
    "#FF1975",
  ];

  const groupBy = (array, key) => {
    return array.reduce((result, obj) => {
      const value = obj.properties[key];
      if (!result[value]) {
        result[value] = [];
      }
      result[value].push(obj);
      return result;
    }, {});
  };
  let overAllCount = data_vector.features.length;
  const updateDataForChart = (
    geo_data,
    update_pie = false,
    update_bar = false
  ) => {
    setDataCount(geo_data.features.length);
    const groupedData = groupBy(geo_data.features, "KLON");
    const groupStatusData = groupBy(geo_data.features, "STATUS");

    const pieChartData = [];
    for (const [key, value] of Object.entries(groupedData)) {
      pieChartData.push({
        name: key,
        value: value.length,
        features: value,
        count: overAllCount,
      });
    }

    const barChartData = [];
    for (const [key, value] of Object.entries(groupStatusData)) {
      barChartData.push({ name: key, value: value.length, features: value });
    }
    if (update_bar) setBarChartData(barChartData);
    if (update_pie) setPieChartData(pieChartData);
  };

  useEffect(() => {
    updateDataForChart(data_vector, true, true);
    setDataCount(data_vector.features.length);
  }, [vectorLayer, map]);

  const onPieEnter = (data, index) => {
    updateGeometry(data.features);
    setState(index);
    setTooltipOpen(true);
    if (!pieChartClicked) pieResetRef.current.classList.toggle("d-none");
    setpieChartClicked(true);
  };

  const onChartReset = () => {
    setState(null);
    setTooltipOpen(false);
    updateDataForChart(data_vector, true, true);
    setpieChartClicked(false);
    setStateBar(null);
    setToolBartipOpen(false);
    pieResetRef.current.classList.toggle("d-none");
    updateGeometry(data_vector.features);
  };
  const onBarEnter = (data, index) => {
    setStateBar(index);
    setState(null);
    updateDataForChart(data, true);
    setToolBartipOpen(true);
    if (!pieChartClicked) pieResetRef.current.classList.toggle("d-none");
    setpieChartClicked(true);
  };

  const updateGeometry = (payload) => {
    vectorLayer.layer.getSource().clear();
    vectorLayer.layer
      .getSource()
      .addFeatures(
        new GeoJSON().readFeatures({ ...data_vector, features: payload })
      );
    map.getView().fit(vectorLayer.layer.getSource().getExtent(), map.getSize());
  };
  const toolToggle = () => {
    toolRef.current.classList.toggle("d-none");
    cardRef.current.classList.toggle("d-none");
    // onChartReset();
  };
  return (
    <>
      <div className="d-flex zn-1 flex-row-reverse parent-chart-container">
        <div className="d-block chart-tool">
          <button
            ref={toolRef}
            title="Chart"
            className="btn"
            style={{ padding: "0px" }}
            onClick={toolToggle}
          >
            <img style={{ width: "38px" }} src={chartsvg} />
          </button>
        </div>
        <div ref={cardRef} className="d-flex flex-row-reverse d-none">
          <div>
            <div className="buttonContainer">
              <button
                className="btn btn-close"
                style={{ width: "38px" }}
                onClick={toolToggle}
              ></button>
            </div>
          </div>
          <div className="d-flex flex-column chart-container">
            <div className="card card-container text-center">
              <h5 className="card-title">JUMLAH POKOK</h5>
              <h1 className="card-text">{dataCount}</h1>
            </div>
            <div className="d-flex flex-column">
              <div className="pie-reset z-1 d-none" ref={pieResetRef}>
                <button className="btn" onClick={onChartReset}>
                  &#x21BA;
                </button>
              </div>
              <div className="pie-chart-container">
                <ResponsiveContainer
                  className="z-n1"
                  width="100%"
                  height="100%"
                >
                  <PieChart width="20vw" height="35vh">
                    <Pie
                      ref={pieChartRef}
                      activeIndex={state}
                      activeShape={renderActiveShape}
                      data={pieChartData}
                      cx="50%"
                      cy="50%"
                      innerRadius={40}
                      outerRadius="20vw"
                      paddingAngle={0}
                      dataKey="value"
                      onClick={onPieEnter}
                    >
                      {pieChartData.map((entry, index) => (
                        <Cell
                          style={{ outline: "none" }}
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip isOpen={tooltipOpen} content={<CustomTooltip />} />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className="bar-chart-container">
                <ResponsiveContainer width="100%" height="100%">
                  <ResponsiveContainer>
                    <BarChart
                      data={barChartData}
                      width="20vw"
                      layout="vertical"
                      height="50vh"
                      margin={{ top: 0, right: 10, left: 5, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis type="number" />
                      <YAxis
                        type="category"
                        fontSize="11"
                        fontFamily="sans-serif"
                        padding={{ left: 5 }}
                        dataKey="name"
                      />
                      <Tooltip isOpen={toolBartipOpen} />
                      <Brush dataKey="value" height={30} stroke="#8884d8" />
                      <Bar
                        activeIndex={stateBar}
                        onClick={onBarEnter}
                        dataKey="value"
                        fill="#FF1975"
                      >
                        {barChartData.map((entry, index) => (
                          <Cell
                            key={"cell" + index.toString()}
                            fill={stateBar === index ? "#FF1975" : "#ffbad5"}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MapPieChart;
