import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { API_BASE_URL, IMAGE_BASE_URL } from '../../constants/ApiConfig';

const LayoutPage = ({ children }) => {

    const mainStyle = {
        marginLeft: '0',
        height: '90vh',
        overflowX: 'auto',
    };

    
    const childrenWithProps = React.Children.map(children, (child) => {
        return React.cloneElement(child, { style: { marginLeft: '0px' } });
    });

    return (
        <div className='base_fixed'>
            <div className=" mt-1  banner">
                <div className='d-flex flex-wrap col-md-12 top_bar justify-content-between mb-2'>
                    <div className='d-flex top_bar'>
                        <div className=''> <img
                            src="/images/main_logo.png"
                            className="img-fluid rounded-start main_logo_layout"
                            alt="..."
                        /></div>
                        <div className='mt-2'> 
                            <h3 className="big_title">SABAHEBAT DURIAN PLANTATION</h3>
                            <h6 className='small_title'>Plantation Details</h6>
                        </div>
                    </div>


                    <div className=''>
                        <Dropdown className='custom-dropdown'>
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                <div className='d-flex profile_top_bar'>
                                    <div>
                                        <i className="ri-user-fill icon_profile img-fluid profile_pic"></i>
                                    </div>
                                    <div className='pro_bar text-left'>
                                        <p className="admin-title">Visitor</p>
                                        <p className="admin-name bold_poppins">Guest</p>
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                        </Dropdown>
                    </div>



                </div>
                <div>

                </div>

            </div>
            <div className='d-flex justify-content-end resp_bar pr-0 resp_public' id='resp_bar'>
                <div className='resp_dropdown top-bar-public'>
                    {/* <Dropdown className='custom-dropdown'>
                        <Dropdown.Toggle variant="" id="dropdown-basic">
                            <div className='d-flex profile_top_bar'>
                                <div>
                                    <img
                                        src="/images/public_user.png"
                                        className="img-fluid profile_pic_resp"
                                        alt="Profile picture"
                                    />
                                </div>
                                <div className='pro_bar text-left'>
                                    <p className="admin-title">Visitor</p>
                                    <p className="admin-name bold_poppins">Guest</p>
                                </div>
                            </div>
                        </Dropdown.Toggle>
                    </Dropdown> */}
                </div>
            </div>

            <div className='qrPage'>
                <div id="public_main" className='' style={mainStyle}>
                    {childrenWithProps}
                </div>
            </div>
        </div>
    );
};

export default LayoutPage;
