import React, { useState, useEffect, useRef } from "react";
import svg from "../../../assets/legend.svg";
import "./legend.css";
import { Style, Icon } from "ol/style.js";

const LegendComponent = ({ styles }) => {
  const toolRef = React.createRef();
  const cardRef = React.createRef();
  const [legendEntries, setLegendEntries] = useState([]);

  useEffect(() => {
    const legendEntries = [];
    Object.keys(styles).forEach((key, idx) => {
      const style = styles[key];
      if (style instanceof Style) {
        const imageStyle = style.getImage();
        if (imageStyle instanceof Icon) {
          const src = imageStyle.getSrc();
          legendEntries.push({ src, key });
        }
      }
    });

    const opts = legendEntries.map((legend) => (
      <div key={legend.key + "div"} className="p-1">
        <label key={legend.key + "label"} htmlFor="">
          {legend.key} -
        </label>
        <img
          key={legend.key + "img"}
          src={legend.src}
          alt=""
          style={{ width: "15px", height: "15px" }}
        />
      </div>
    ));
    setLegendEntries(opts);
    // const data = getLegendEntries(vectorLayer);
  }, [styles]);

  const toolToggle = () => {
    // toolRef.current.classList.toggle("d-none");

    cardRef.current.classList.toggle("d-none");
  };
  return (
    <>
      <div className="legend-container z-1 d-flex flex-column">
        <div className="d-block">
          <button
            title="Legend"
            ref={toolRef}
            className="btn"
            style={{ padding: "0px" }}
            onClick={toolToggle}
          >
            <img style={{ width: "38px" }} src={svg} />
          </button>
        </div>
        <div className="card d-none" ref={cardRef}>
          {legendEntries}
        </div>
      </div>
    </>
  );
};

export default LegendComponent;
