// import LayoutPage from './Base';
import LayoutPage from '../layouts/base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../constants/ApiConfig';
import { Button, Form, Modal } from 'react-bootstrap';
import SuccessModal from "../components/success-modal";
import { useAuth } from '../../services/AuthProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


function PhaseListingPage() {

    const { user, hasPermission } = useAuth();

    const canCreatePhase = hasPermission('create-phase');
    const canEditPhase = hasPermission('edit-phase');
    const canDeletePhase = hasPermission('delete-phase');

    const itemsPerPage = 10;

    const [phaseData, setPhaseData] = useState([]);
    const handlePhaseModal = () => setShowModal(true);
    const [phaseName, setPhaseName] = useState('');
    const handleModalClose = () => setShowModal(false);
    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [editPhaseName, setEditPhaseName] = useState('');
    const [editPhaseId, setEditPhaseId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const handleEditModalClose = () => setShowEditModal(false);
    const [showSuccessDeleteModal, setshowSuccessDeleteModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    useEffect(() => {
        const fetchPhaseData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}phase/index`);
                if (response.ok) {
                    const data = await response.json();
                    setPhaseData(data)
                }
            } catch (error) {
                console.error('Error fetching phase data:', error);
            }
        };

        fetchPhaseData();
    }, []);

    const handleNameChange = (e) => {
        setPhaseName(e.target.value);
    };

    const handleSubmitPhase = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}phase/store`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ phaseName }),
            });

            if (response.status == 200) {
                setShowModal(false);
                setShowSuccessModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    }

    const handleEditPhaseModal = (phaseId) => {
        setEditPhaseId(phaseId);
        setShowEditModal(true);
    };

    // Function to fetch zone data by ID
    const fetchPhaseById = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}phase/edit/${id}`);
            if (response.ok) {
                const data = await response.json();
                console.log(data)
                setEditPhaseName(data.name);
            }
        } catch (error) {
            console.error('Error fetching phase:', error);
        }
    };

    useEffect(() => {
        if (editPhaseId !== null) {
            fetchPhaseById(editPhaseId);
        }
    }, [editPhaseId]);

    const handleEditedNameChange = (event) => {
        setEditPhaseName(event.target.value);
    };

    const handleSubmitEditPhase = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}phase/update/${editPhaseId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ phaseName: editPhaseName }),
            });

            if (response.ok) {
                setShowEditModal(false);
                setShowSuccessModal(true);
            }
        } catch (error) {
            // Handle fetch errors
            console.error('Error updating zone:', error);
        }
    }

    const handleDeletePhase = async (index) => {
        try {
            const response = await fetch(`${API_BASE_URL}phase/destroy/${index}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status == 200) {
                setShowDeleteModal(false);
                setshowSuccessDeleteModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const handleConfirmDelete = (id) => {
        setDeleteIndex(id);
        setShowDeleteModal(true);
    };


    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    // Pagination Start
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = phaseData.slice(indexOfFirstItem, indexOfLastItem);

    const renderIndex = (index) => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };
    // Pagination end

    return (
        <LayoutPage>
            <div className='children'>
                <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                    <h4 className="mt-3 pg_title">
                     LIST OF PHASES
                    </h4>
                </div>
                {canCreatePhase && <div className="addDisease mt-3 d-flex justify-content-center mb-4" onClick={handlePhaseModal}>
                    <div className="icon">
                        <FontAwesomeIcon className="icon" icon={faPlus} />
                    </div>
                    <div className="text ml-3">
                        Phase
                    </div>
                </div>}

                <div>
                    {currentItems.map((phase, index) => (
                        <div key={index} className="listBar p-3 my-3">
                            <div className="d-flex justify-content-between mx-4">
                                <div className="regular_poppins">
                                    {renderIndex(index)}. {phase.name}
                                </div>
                                <div className="regular_poppins">
                                    {canEditPhase &&
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                            <FontAwesomeIcon icon={faPencil} className="icon edit-icon" onClick={() => handleEditPhaseModal(phase.id)} />
                                        </OverlayTrigger>
                                    }
                                    {canDeletePhase &&
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>} >
                                            <FontAwesomeIcon icon={faTrash} className="icon delete-icon" onClick={() => handleConfirmDelete(phase.id)} />
                                        </OverlayTrigger>
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className='pagination'>
                        <button onClick={handlePrevPage} disabled={currentPage === 1}>
                            Prev
                        </button>
                        <button onClick={handleNextPage} disabled={indexOfLastItem >= phaseData.length}>
                            Next
                        </button>
                    </div>
                </div>

            </div>

            {/* Modal Create Phase */}
            <Modal className='modal_responsive' show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Phase</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Your form content */}
                    <Form onSubmit={handleSubmitPhase}>
                        <Form.Group controlId="formPhaseName" >
                            <Form.Label className="label-left">Phase Name</Form.Label>
                            <Form.Control value={phaseName} onChange={handleNameChange} type="text" placeholder="Enter phase name" required />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal Update Zone */}
            <Modal className='modal_responsive' show={showEditModal} onHide={handleEditModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Phase</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitEditPhase}>
                        <Form.Group controlId="formPhaseName" >
                            <Form.Label className="label-left">Phase Name</Form.Label>
                            <Form.Control
                                value={editPhaseName}
                                onChange={handleEditedNameChange}
                                type="text"
                                placeholder="Enter phase name"
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Update
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal/Popup for Success Message */}
            <SuccessModal
                className='modal_responsive'
                show={showSuccessModal}
                onHide={closeSuccessModal}
                title="Success"
                modalBody={<p>Data successfully saved!</p>}
                buttonText="Close"
            />

            {/* Delete Modal */}
            <Modal className='modal_responsive' show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this phase?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button className="delete-button" onClick={() => handleDeletePhase(deleteIndex)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal/Popup for Confirmation Delete */}
            <SuccessModal
                className='modal_responsive'
                show={showSuccessDeleteModal}
                onHide={closeSuccessModal}
                title="Data Deleted"
                modalBody={<p>Data was successfully deleted!</p>}
                buttonText="OK"
            />

        </LayoutPage>
    )
}

export default PhaseListingPage