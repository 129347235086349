import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../services/AuthProvider";
import LayoutPage from "../../layouts/base"
import { useEffect, useRef, useState } from "react";
import { API_BASE_URL } from "../../../constants/ApiConfig";
import { Chart } from "chart.js";


function ReportByTypePage() {

    const [isLoading, setIsLoading] = useState(true);
    const chartCategory = useRef(null);
    const [cloneData, setCloneData] = useState([]);
    const [totalPlant, setTotalPlantData] = useState('');

    useEffect(() => {
        let newChartCategory;

        // Data for the pie chart
        const fetchCategoryData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}report/clone`);
                const data = await response.json();

                setTotalPlantData(data.total_plant);
                
                const statistics = data.statistics;
                console.log(statistics)

                const labels = statistics.map(item => item.name);  console.log(labels)
                const values = statistics.map(item => item.total_plantations); console.log(values)

                const backgroundColors = generateRandomColors(labels.length);

                const chartData = {
                    labels,
                    datasets: [{
                        data: values,
                        backgroundColor: backgroundColors,
                    }],
                };

                // Configuration options for the pie chart
                const options = {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                        },
                        tooltip: {
                            callbacks: {
                                label: (context) => {
                                    const label = context.label || 'Others';
                                    const value = context.formattedValue || '';
                                    const percentage = context.dataset.data[context.dataIndex] / context.dataset.data.reduce((a, b) => a + b, 0) * 100;
                                    return `${value} Plant`;
                                },
                            },
                        },
                    },
                };

                // Get the context of the canvas element
                const ctx = chartCategory.current.getContext('2d');

                if (newChartCategory) {
                    newChartCategory.destroy();
                }

                setIsLoading(false); 

                // Create the pie chart
                newChartCategory = new Chart(ctx, {
                    type: 'pie',
                    data: chartData,
                    options,
                });

                chartCategory.current = newChartCategory;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchCategoryData();

        return async () => {
            if (newChartCategory) {
                await newChartCategory.destroy();
            }
        };
    }, []);

    // Function to generate random colors
    const generateRandomColors = (count) => {
        const backgroundColors = [];
        const hueStep = 360 / count; // Calculate the step for distributing hues evenly
        for (let i = 0; i < count; i++) {
            const hue = (hueStep * i) % 360; // Generate hue based on step
            const color = getRandomColor(hue);
            backgroundColors.push(color);
        }
        return backgroundColors;
    };

    // Function to generate a random color with specific hue
    const getRandomColor = (hue) => {
        const s = 80; // Fixed saturation for vibrant colors
        const l = 50; // Fixed lightness for consistent brightness

        return `hsl(${hue}, ${s}%, ${l}%)`;
    };


    return (
        <LayoutPage>
            <div className="children">
                {isLoading && (
                    <div className="spinner-overlay">
                        <div className="spinner"></div>
                    </div>
                )}
                <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                    <h4 className="mt-3 pg_title">
                        Report By Clone
                    </h4>
                </div>
                <div className="row px-4 pb-5">
                    <div className="card w-100 mb-3">
                        <div className="card-body">
                            <div className="text-center">
                                <h4 className="total-title">Total Plantations</h4>
                                <h2 className="total-plant">{totalPlant}</h2>
                            </div>
                        </div>
                    </div>
                    <div className='card w-100 mb-3'>
                        <div className='card-body'>
                            <h5 className='card-title'>Clones Overview</h5>
                            <div className="py-4"><canvas ref={chartCategory}></canvas></div>
                        </div>
                    </div>
                </div>
            </div>

        </LayoutPage>
    )
}

export default ReportByTypePage