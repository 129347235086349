import LayoutPage from '../layouts/base';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import React, { useEffect, useState } from 'react';
import { API_BASE_URL, IMAGE_BASE_URL, SABAHEBAT_DEV_URL } from '../../constants/ApiConfig';
import { Page, Document, pdf, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { pdfjs } from 'react-pdf';
import QRCodeNew from "qrcode";

const styles = StyleSheet.create({
    page: {
      flex: 1,
      padding: 20,
      zIndex: 1,
    },
    section: {
      margin: 30,
      padding: 30,
      flexGrow: 1,
      border: '1 solid #fff',
      backgroundColor: '#fff',
      borderRadius: 10,
    },
    title: {
      fontSize: 24,
      marginBottom: 10,
    },
    content: {
      fontSize: 16,
    },
    header: {
      paddingTop: 30,
      paddingBottom: 10,
      alignItems: 'center',
    },
    zoneText: {
      fontSize: 28,
      fontWeight: 800, 
      top: 25,
      marginLeft: 30,
    },
    centeredText: {
      fontSize: 18,
      textAlign: 'center',
      fontWeight: 'bold',
      marginBottom: 4,
    },
    logoContainer: {
      position: 'absolute',
      top: -100,
      left: -10,
      zIndex: 2,
    },
    mainLogo: {
      width: 100,
      height: 100,
    },
    qrCodeImage: {
        width: 250,
        height: 250,
    },
  });

function GeneratedQRPage() {

    const [divisionData, setDivisionData] = useState([]);
    const [blockData, setBlockData] = useState([]);
    const [subBlockData, setSubBlockData] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState('');
    const [selectedBlock, setSelectedBlock] = useState('');
    const [selectedSubBlock, setSelectedSubBlock] = useState('');
    const [divisionName, setDivisionName] = useState('');
    const [blockName, setBlockName] = useState('');
    const [subBlockName, setSubBlockName] = useState('');
    const [plantationData, setPlantationData] = useState([]);

    useEffect(() => {
        const fetchDivisionData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}division/index`);
                if (response.ok) {
                    const data = await response.json();
                    const divisions = data.divisions;
                    setDivisionData(divisions)
                    if (divisions.length > 0) {
                        setSelectedDivision(divisions[0].id);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchDivisionData();
    }, []);

    useEffect(() => {
        const fetchBlockData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}block/fetchBlockData/${selectedDivision}`);
                if (response.ok) {
                    const data = await response.json();
                    const blocks = data.blocks;
                    setBlockData(blocks)
                    if (blocks.length > 0) {
                        setSelectedBlock(blocks[0].id);
                    }
                }
            } catch (error) {
                console.error('Error fetching block data:', error);
            }
        };

        fetchBlockData();
    }, [selectedDivision]);

    useEffect(() => {
        const fetchSubBlockData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}subBlock/index/${selectedBlock}`);
                if (response.ok) {
                    const data = await response.json();
                    const subBlocks = data.subBlocks;
                    setSubBlockData(subBlocks)
                    if (subBlocks.length > 0) {
                        setSelectedSubBlock(subBlocks[0].id);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchSubBlockData();
    }, [selectedBlock]);

    const handleDivisionChange = (e) => {
        setSelectedDivision(e.target.value);
    };

    const handleBlockChange = (e) => {
        setSelectedBlock(e.target.value);
    };

    const handleSubBlockChange = (e) => {
        setSelectedSubBlock(e.target.value);
    };

    useEffect(() => {
        if (selectedSubBlock !== '') {
            fetch(`${API_BASE_URL}plantation/index/${selectedSubBlock}`)
                .then((response) => response.json())
                .then((data) => {
                    const plantations = data.plantations; console.log(plantations);
                    setPlantationData(plantations);
                    setBlockName(data.block_name);
                    setDivisionName(data.division_name);
                    setSubBlockName(data.sub_block_name);
                })
                .catch((error) => {
                    console.error('Error fetching plantation data:', error);
                });
        }
    }, [selectedSubBlock]);

    const generatePDF = async () => {
       
        const pdfData = plantationData.map(async (plantation, index) => {
            const canvas = document.createElement('canvas');
            const url = `${SABAHEBAT_DEV_URL}/plantation/edit/${plantation.id}?source=qrscan`;
            const redirectUrl = encodeURIComponent(url);
            const viewUrl = `${SABAHEBAT_DEV_URL}/public/plantation/view/${plantation.id}?source=qrscan`;
            const redirectViewUrl = encodeURIComponent(viewUrl);
            const loginUrl = `${SABAHEBAT_DEV_URL}/?redirectUrl=${redirectUrl}&redirectViewUrl=${redirectViewUrl}`;
            QRCodeNew.toCanvas(canvas, loginUrl);
            const qrCodeDataURL = canvas.toDataURL('image/png');
        
            return (
                <Page size={'A5'} key={index}>
                    <View style={styles.header}>
                    <Text style={styles.zoneText}>{`${plantation.division_name}`}</Text>
                    </View>
                    <View style={styles.page}>
                        <View style={styles.section}>
                            <View style={styles.logoContainer}>
                                <Image src='/images/main_logo.png' style={styles.mainLogo} />
                            </View>
                            <Text style={styles.centeredText}>{`Block ${blockName} (${plantation.sub_block_name})`}</Text>
                            {plantation.plant_no && (
                                <Text style={styles.centeredText}>{`Plant No: ${plantation.plant_no}`} </Text>
                            )}
                            <Image src={qrCodeDataURL} style={styles.qrCodeImage} />
                        </View>
                    </View>
                </Page>
            );
        });

        const resolvedPdfData = await Promise.all(pdfData);
      
        // Open the generated PDF in a new tab
        pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
    
        const blob = await pdf(
        <Document>{resolvedPdfData}</Document>,
        { size: 'A5', margin: 20 }
        ).toBlob();
    
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `QR_${divisionName}_Block ${blockName}_${subBlockName}.pdf`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <LayoutPage>
            <div className='children'>
                <h4 className='mt-3 bold_poppins pg_title'> QR <img
                    src="/images/qrcode_icon.png"
                    className="img-fluid qr_icon2 w3-black"
                    alt="..."
                /> Generator</h4>

                <div className="row mb-3 mt-5">
                    <div className="col-md-2 col-12">
                        <Form.Label className="label_control" htmlFor="basic-url">Division :</Form.Label>
                    </div>
                    <div className="col-md-10 col-12">
                        <Form.Select
                            onChange={handleDivisionChange}
                            value={selectedDivision}
                        >
                            {divisionData.map((division) => (
                                <option key={division.id} value={division.id}>
                                    {division.name}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-2 col-12">
                        <Form.Label className="label_control" htmlFor="basic-url">Block :</Form.Label>
                    </div>
                    <div className="col-md-10 col-12">
                        <Form.Select
                            onChange={handleBlockChange}
                            value={selectedBlock}
                        >
                            {blockData.map((block) => (
                                <option key={block.id} value={block.id}>
                                    Block {block.name}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-2 col-12">
                        <Form.Label className="label_control" htmlFor="basic-url">Sub-Block :</Form.Label>
                    </div>
                    <div className="col-md-10 col-12">
                        <Form.Select
                            onChange={handleSubBlockChange}
                            value={selectedSubBlock}
                        >
                            {subBlockData.map((subBlock) => (
                                <option key={subBlock.id} value={subBlock.id}>
                                    {subBlock.name}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                </div>
                <div className='mt-4 generateBtnDiv'>
                    <button className='btn qrGenBtn' onClick={generatePDF}>GENERATE</button>
                </div>
                {/* <div className='text-center contentqr'>
                    <div className='form_btn mt-3'>
                        <button id="saveButton" className='saveQR_btn' target="_blank" rel="noopener noreferrer">
                            Save
                        </button>
                        <button id="printButton" className='printQR_btn' target="_blank" rel="noopener noreferrer">
                            Print
                        </button>
                    </div>
                </div> */}

            </div>
        </LayoutPage>
    )
}

export default GeneratedQRPage