// import LayoutPage from './Base';
import LayoutPage from '../layouts/base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../constants/ApiConfig';
import { Button, Form, Modal } from 'react-bootstrap';
import SuccessModal from "../components/success-modal";
import { useAuth } from '../../services/AuthProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


function DiseaseListingPage() {

    const { user, hasPermission } = useAuth();

    const canManageDisease = hasPermission('manage-disease');
    const canCreateDisease = hasPermission('create-disease');
    const canEditDisease = hasPermission('edit-disease');
    const canDeleteDisease = hasPermission('delete-disease');

    const itemsPerPage = 10;

    const [diseaseData, setDiseaseData] = useState([]);
    const handleDiseaseModal = () => setShowModal(true);
    const [diseaseName, setDiseaseName] = useState('');
    const handleModalClose = () => setShowModal(false);
    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [editDiseaseName, setEditDiseaseName] = useState('');
    const [editDiseaseId, setEditDiseaseId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const handleEditModalClose = () => setShowEditModal(false);
    const [showSuccessDeleteModal, setshowSuccessDeleteModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    useEffect(() => {
        const fetchDiseaseData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}disease/index`);
                if (response.ok) {
                    const data = await response.json();
                    setDiseaseData(data)
                }
            } catch (error) {
                console.error('Error fetching zone data:', error);
            }
        };

        fetchDiseaseData();
    }, []);

    const handleNameChange = (e) => {
        setDiseaseName(e.target.value);
    };

    const handleSubmitDisease = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}disease/store`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ diseaseName }),
            });

            if (response.status == 200) {
                setShowModal(false);
                setShowSuccessModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    }

    const handleEditDiseaseModal = (diseaseId) => {
        setEditDiseaseId(diseaseId);
        setShowEditModal(true);
    };

    // Function to fetch zone data by ID
    const fetchDiseaseById = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}disease/edit/${id}`);
            if (response.ok) {
                const data = await response.json();
                console.log(data)
                setEditDiseaseName(data.name);
            }
        } catch (error) {
            console.error('Error fetching zone:', error);
        }
    };

    useEffect(() => {
        if (editDiseaseId !== null) {
            fetchDiseaseById(editDiseaseId);
        }
    }, [editDiseaseId]);

    const handleEditedNameChange = (event) => {
        setEditDiseaseName(event.target.value);
    };

    const handleSubmitEditDisease = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}disease/update/${editDiseaseId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ diseaseName: editDiseaseName }),
            });

            if (response.ok) {
                setShowEditModal(false);
                setShowSuccessModal(true);
            }
        } catch (error) {
            // Handle fetch errors
            console.error('Error updating zone:', error);
        }
    }

    const handleDeleteDisease = async (index) => {
        try {
            const response = await fetch(`${API_BASE_URL}disease/destroy/${index}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status == 200) {
                setShowDeleteModal(false);
                setshowSuccessDeleteModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const handleConfirmDelete = (id) => {
        setDeleteIndex(id);
        setShowDeleteModal(true);
    };


    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    // Pagination Start
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = diseaseData.slice(indexOfFirstItem, indexOfLastItem);

    const renderIndex = (index) => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };
    // Pagination end

    return (
        <LayoutPage>
            <div className='children'>
                <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                    <h4 className="mt-3 pg_title">
                        DISEASES LIST
                    </h4>
                </div>
                {canCreateDisease && <div className="addDisease mt-3 d-flex justify-content-center mb-4" onClick={handleDiseaseModal}>
                    <div className="icon">
                        <FontAwesomeIcon className="icon" icon={faPlus} />
                    </div>
                    <div className="text ml-3">
                        Disease
                    </div>
                </div>}

                <div>
                    {currentItems.map((disease, index) => (
                        <div key={index} className="listBar p-3 my-3">
                            <div className="d-flex justify-content-between mx-4">
                                <div className="regular_poppins">
                                    {renderIndex(index)}. {disease.name}
                                </div>
                                <div className="regular_poppins">
                                    {canEditDisease &&
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                            <FontAwesomeIcon icon={faPencil} className="icon edit-icon" onClick={() => handleEditDiseaseModal(disease.id)} />
                                        </OverlayTrigger>
                                    }
                                    {canDeleteDisease &&
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>} >
                                            <FontAwesomeIcon icon={faTrash} className="icon delete-icon" onClick={() => handleConfirmDelete(disease.id)} />
                                        </OverlayTrigger>
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className='pagination'>
                        <button onClick={handlePrevPage} disabled={currentPage === 1}>
                            Prev
                        </button>
                        <button onClick={handleNextPage} disabled={indexOfLastItem >= diseaseData.length}>
                            Next
                        </button>
                    </div>
                </div>

            </div>

            {/* Modal Create Disease */}
            <Modal className='modal_responsive' show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Disease</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Your form content */}
                    <Form onSubmit={handleSubmitDisease}>
                        <Form.Group controlId="formDiseaseName" >
                            <Form.Label className="label-left">Disease Name</Form.Label>
                            <Form.Control value={diseaseName} onChange={handleNameChange} type="text" placeholder="Enter disease name" required />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal Update Zone */}
            <Modal className='modal_responsive' show={showEditModal} onHide={handleEditModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Disease</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitEditDisease}>
                        <Form.Group controlId="formDiseaseName" >
                            <Form.Label className="label-left">Disease Name</Form.Label>
                            <Form.Control
                                value={editDiseaseName}
                                onChange={handleEditedNameChange}
                                type="text"
                                placeholder="Enter disease name"
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Update
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal/Popup for Success Message */}
            <SuccessModal
                className='modal_responsive'
                show={showSuccessModal}
                onHide={closeSuccessModal}
                title="Success"
                modalBody={<p>Data successfully saved!</p>}
                buttonText="Close"
            />

            {/* Delete Modal */}
            <Modal className='modal_responsive' show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this disease?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button className="delete-button" onClick={() => handleDeleteDisease(deleteIndex)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal/Popup for Confirmation Delete */}
            <SuccessModal
                className='modal_responsive'
                show={showSuccessDeleteModal}
                onHide={closeSuccessModal}
                title="Data Deleted"
                modalBody={<p>Data was successfully deleted!</p>}
                buttonText="OK"
            />

        </LayoutPage>
    )
}

export default DiseaseListingPage