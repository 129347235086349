import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { API_BASE_URL, SABAHEBAT_DEV_URL } from '../../constants/ApiConfig';
import { PDFDownloadLink } from '@react-pdf/renderer';
import MyDocument from '../plantation/DocumentView';
import QRCode from 'qrcode.react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../services/auth';

function QRCodeModal(props) {
    const navigate = useNavigate()

    const plantationId = props.id;
    const qrCodeImageData = props.qrCodeImageData;
    const url = `${SABAHEBAT_DEV_URL}/plantation/edit/${plantationId}?source=qrscan`;
    const redirectUrl = encodeURIComponent(url);
    const viewUrl = `${SABAHEBAT_DEV_URL}/public/plantation/view/${plantationId}?source=qrscan`;
    const redirectViewUrl = encodeURIComponent(viewUrl);
    const loginUrl = `${SABAHEBAT_DEV_URL}/?redirectUrl=${redirectUrl}&redirectViewUrl=${redirectViewUrl}`;

    const [plantationData, setPlantationData] = useState([]);

    useEffect(() => {
        const fetchTamanData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}plantation/edit/${plantationId}`);
                if (response.ok) {
                    const data = await response.json();
                    setPlantationData(data.plantation)
                }
            } catch (error) {
                console.error('Error fetching plantation data:', error);
            }
        };

        fetchTamanData();
    }, [plantationId]);

    const handleLogoutAndRedirect = async (e) => {
        e.preventDefault();

        const url = `${SABAHEBAT_DEV_URL}/plantation/edit/${plantationId}?source=qrscan`;
        const redirectUrl = encodeURIComponent(url);
        const viewUrl = `${SABAHEBAT_DEV_URL}/public/plantation/view/${plantationId}?source=qrscan`;
        const redirectViewUrl = encodeURIComponent(viewUrl);
        const loginUrl = `${SABAHEBAT_DEV_URL}/?redirectUrl=${redirectUrl}&redirectViewUrl=${redirectViewUrl}`;

        const USER_ID_KEY = 'user_id';
        const userId = localStorage.getItem(USER_ID_KEY);
        console.log(userId);

        try {
            const response = await fetch(`${API_BASE_URL}logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });

            if (response.ok) {
                logout();
                window.open(loginUrl, '_blank');
            }
        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    }

    return (
        <Modal
            className='modal_responsive'
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter modal_qr"
            centered
        >
            <div className="mb-3">
                <Modal.Header closeButton>
                    <h3>QR Code Preview</h3>
                </Modal.Header>
            </div>
            <Modal.Body className="bg_green p-5 mb-3">
                <div className="bg-white p-0">
                    <div className="text-left">
                        <img
                            src="/images/main_logo.png"
                            className="img-fluid logo_inQR"
                            alt="..."
                        />
                    </div>

                    <div >
                        <h4 className="bold_poppins"><h3>{plantationData.division_name} - Block {plantationData.block_name}({plantationData.sub_block_name})</h3></h4>
                        <QRCode value={loginUrl} className="my-3 qr_preview" />
                        <h4 className="bold_poppins">Plant No: {plantationData.plant_no}</h4>
                    </div>
                    <div className='form_btn mt-3 d-flex flex-wrap justify-content-around'>
                        <PDFDownloadLink className=""
                            document={<MyDocument
                                blockName={plantationData.block_name}
                                plantation={plantationData}
                                qrCodeImageData={qrCodeImageData}
                            />}
                            fileName={`${plantationData.division_name}_Block ${plantationData.block_name}_${plantationData.block_no}_${plantationData.plant_no}.pdf`}
                        >
                            {({ loading }) => (
                                <button className='printQR_btn'>
                                    {loading ? 'Loading document...' : 'Print PDF'}
                                </button>
                            )}
                        </PDFDownloadLink>
                        {/* <button className='btn formBatalBtn mb-2' onClick={handleLogoutAndRedirect}>View</button> */}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default QRCodeModal;
