// import LayoutPage from './Base';
import LayoutPage from '../layouts/base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencil, faTrash, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../constants/ApiConfig';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import SuccessModal from "../components/success-modal";
import { useAuth } from '../../services/AuthProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { useBlockId } from '../../services/BlockIdContext';


function SubBlockListingPage() {

    const { user, hasPermission } = useAuth();

    const navigate = useNavigate();

    const canManageSubblock = hasPermission('manage-subblock');
    const canCreateSubblock = hasPermission('create-subblock');
    const canEditSubblock = hasPermission('edit-subblock');
    const canDeleteSubblock = hasPermission('delete-subblock');

    const itemsPerPage = 10;

    const { blockId } = useParams();
    const [blockData, setBlockData] = useState([]);
    const [subBlockData, setSubBlockData] = useState([]);
    const handleBlockModal = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);
    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showSuccessDeleteModal, setshowSuccessDeleteModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [editSubBlockId, setEditSubBlockId] = useState(null);
    const [editBlockName, setEditBlockName] = useState('');
    const [existingSubBlockData, setExistingSubBlockData] = useState([]);

    const [formData, setFormData] = useState({
        name: '',
        areaHectar: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
            blockId: blockId,
        });
    };


    useEffect(() => {
        const fetchSubBlockData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}subBlock/index/${blockId}`);
                if (response.ok) {
                    const data = await response.json();
                    setBlockData(data.block);
                    setSubBlockData(data.subBlocks);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchSubBlockData();
    }, []);

    const handleSubmitSubBlock = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}subBlock/store/${blockId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.status == 200) {
                setShowModal(false);
                setShowSuccessModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    }

    const handleModalEditSubBlock = (subBlockId) => {
        setEditSubBlockId(subBlockId);
        setShowEditModal(true);
    };

    // Function to data by ID
    const fetchSubBlockById = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}subBlock/edit/${id}`);
            if (response.ok) {
                const data = await response.json();
                console.log(data)
                const block = data.block;
                setEditBlockName(block.name);
                setExistingSubBlockData(data.subBlock);

            }
        } catch (error) {
            console.error('Error fetching block:', error);
        }
    };

    useEffect(() => {
        if (editSubBlockId !== null) {
            fetchSubBlockById(editSubBlockId);
        }
    }, [editSubBlockId]);

    useEffect(() => {
        if(existingSubBlockData && Object.keys(existingSubBlockData).length > 0) {
            const blockName = existingSubBlockData.block_name;
            const subBlockName = existingSubBlockData.name;

            const blockNameLength = blockName.length;
            const parsedSubBlockName  = subBlockName.substring(blockNameLength);

            setFormData({
                name: parsedSubBlockName || '',
                areaHectar: existingSubBlockData.area_hectar || '',
            });
        }
      }, [existingSubBlockData]);

    const handleSubmitEditSubBlock = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}subBlock/update/${editSubBlockId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setEditSubBlockId('');
                setShowEditModal(false);
                setShowSuccessModal(true);
            }
        } catch (error) {
            // Handle fetch errors
            console.error('Error updating zone:', error);
        }
    }

    const handleDeleteBlock = async (index) => {
        try {
            const response = await fetch(`${API_BASE_URL}subBlock/destroy/${index}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status == 200) {
                setShowDeleteModal(false);
                setshowSuccessDeleteModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const handleConfirmDelete = (id) => {
        setDeleteIndex(id);
        setShowDeleteModal(true);
    };


    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = subBlockData.slice(indexOfFirstItem, indexOfLastItem);

    const renderIndex = (index) => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };

    const totalPages = Math.ceil(subBlockData.length / itemsPerPage);
    const maxPagesToShow = 5;

    // Change page
    const paginate = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) {
            return;
        }
        setCurrentPage(pageNumber);
    };

    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    const totalEntries = subBlockData.length;
    const startEntry = renderIndex(0);
    const endEntry = renderIndex(currentItems.length - 1);

    // Function to generate an array of page numbers to display
    const getPageNumbers = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, index) => start + index);
    };

    const handleEditModalClose = () => {
        setEditSubBlockId('');
        setShowEditModal(false);
    };

    const { setBlockId, setDivisionId, setSubBlockId, setBlockAndDivisionId, isSet } = useBlockId();

    useEffect(() => {
        if (isSet) {
            navigate('/plantation');
        }
    }, [isSet, navigate]);

    const fetchBlockById = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}subBlock/fetchData/${id}`);
            if (response.ok) {
                const data = await response.json();
                setBlockId(data.block.id);
                setDivisionId(data.block.division_id);
                setBlockAndDivisionId(data.block.id, data.block.division_id);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    const handlePlantationClick = (subBlockId) => {
        fetchBlockById(subBlockId);
        setSubBlockId(subBlockId);
    };

    return (
        <LayoutPage>
            <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                <h4 className="mt-3 pg_title">
                    LIST OF SUB-BLOCKS
                </h4>
                <div className="row mr-3">
                    <div className="col">
                        <Form.Control
                            value={blockData.division_name ? blockData.division_name : "No division found"}
                            readOnly
                        />
                    </div>
                    <div className="col">
                        <Form.Control
                            value={blockData.name ? `Block ${blockData.name}` : "No block found"}
                            readOnly
                        />
                    </div>
                </div>
            </div>
            <div className='children'>
                {canCreateSubblock &&
                    <div className="addDisease d-flex justify-content-center mb-4" onClick={handleBlockModal}>
                        <div className="icon">
                            <FontAwesomeIcon className="icon" icon={faPlus} />
                        </div>
                        <div className="text ml-3">
                            Sub-Block
                        </div>
                    </div>
                }

                <div className='m-3 table-container table_responsive'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Name</th>
                                <th>Area (Hectares)</th>
                                {(canManageSubblock || canEditSubblock || canDeleteSubblock) && <th className='text-center'>Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((subBlock, index) => (
                                    <tr key={index}>
                                        <td>{renderIndex(index)}</td>
                                        <td>{subBlock.name || '-'}</td>
                                        <td>{subBlock.area_hectar || '0.00'}</td>
                                        {(canManageSubblock || canEditSubblock || canDeleteSubblock) && <td className='text-center'>
                                            <div className="unwrap editdelete_icon">
                                                {canManageSubblock &&  <i 
                                                    className="ri-plant-line icon edit-icon" 
                                                    style={{ fontSize: '20px' }}
                                                    title="Plantation"
                                                    onClick={() => handlePlantationClick(subBlock.id)}
                                                ></i>}
                                                {canEditSubblock && <FontAwesomeIcon
                                                    icon={faPencil}
                                                    className="icon edit-icon"
                                                    onClick={() => handleModalEditSubBlock(subBlock.id)}
                                                />}
                                                {canDeleteSubblock && <FontAwesomeIcon
                                                    icon={faTrash}
                                                    className="icon delete-icon"
                                                    onClick={() => handleConfirmDelete(subBlock.id)}
                                                />}
                                            </div>
                                        </td>}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="10" className="text-center">No data found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex flex-wrap-reverse">
                    <div className='col-md-6'>
                        <p className='pl-3' style={{ fontFamily: 'Poppins' }}>
                            Showing {startEntry} to {endEntry} of {totalEntries} entries
                        </p>
                    </div>

                    {/* Pagination component */}
                    <div className='col-md-6 pagination flex-wrap'>
                        <div>
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <button onClick={() => paginate(currentPage - 1)} className="page-link">
                                    Previous
                                </button>
                            </li>
                        </div>
                        <div className='d-flex flex-wrap'>
                            {getPageNumbers(startPage, endPage).map((page) => (
                                <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                                    <button onClick={() => paginate(page)} className="page-link">
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </div>
                        <div >
                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                <button onClick={() => paginate(currentPage + 1)} className="page-link">
                                    Next
                                </button>
                            </li>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Create Block */}
            <Modal className='modal_responsive' show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Sub-Block</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Your form content */}
                    <Form onSubmit={handleSubmitSubBlock}>
                        <Form.Group controlId="formBlockName" >
                            <Form.Label className="label-left">Block</Form.Label>
                            <Form.Control value={blockData.name} onChange={handleInputChange} name="block" type="text" placeholder="Enter block name" disabled />
                        </Form.Group>
                        <Form.Group controlId="formBlockName" className="mt-2">
                            <Form.Label className="label-left">Sub-Block Name</Form.Label>
                            <div>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>{blockData.name}</InputGroup.Text>
                                    <Form.Control value={formData.name} onChange={handleInputChange} name="name" type="text" placeholder="Enter sub-block name" required />
                                </InputGroup>
                            </div> 
                        </Form.Group>
                        <Form.Group controlId="formBlockName" className="mt-2">
                            <Form.Label className="label-left">Area (Hectar)</Form.Label>
                            <Form.Control value={formData.areaHectar} onChange={handleInputChange} name="areaHectar" type="number" placeholder="Enter area hectares" required />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal/Popup for Success Message */}
            <SuccessModal
                className="modal_responsive"
                show={showSuccessModal}
                onHide={closeSuccessModal}
                title="Success"
                modalBody={<p>Data successfully saved!</p>}
                buttonText="Close"
            />

            {/* Modal Update Zone */}
            <Modal className='modal_responsive' show={showEditModal} onHide={handleEditModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Sub-Block</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitEditSubBlock}>
                        <Form.Group controlId="formBlockName">
                            <Form.Label className="label-left">Block</Form.Label>
                            <Form.Control value={blockData.name} disabled />
                        </Form.Group>
                        <Form.Group controlId="formBlockName" className="mt-2">
                            <Form.Label className="label-left">Sub-Block Name</Form.Label>
                            <div>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>{blockData.name}</InputGroup.Text>
                                    <Form.Control value={formData.name} onChange={handleInputChange} name="name" type="text" placeholder="Enter sub-block name" required />
                                </InputGroup>
                            </div> 
                        </Form.Group>
                        <Form.Group controlId="formBlockName" className="mt-2">
                            <Form.Label className="label-left">Area (Hectares)</Form.Label>
                            <Form.Control value={formData.areaHectar} onChange={handleInputChange} name="areaHectar" type="number" placeholder="Enter area hectares" required />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Update
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Delete Modal */}
            <Modal className='modal_responsive' show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this sub-block?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button className="delete-button" onClick={() => handleDeleteBlock(deleteIndex)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal/Popup for Confirmation Delete */}
            <SuccessModal
                className="modal_responsive"
                show={showSuccessDeleteModal}
                onHide={closeSuccessModal}
                title="Data Deleted"
                modalBody={<p>Data was successfully deleted!</p>}
                buttonText="OK"
            />
           
        </LayoutPage>
    )
}

export default SubBlockListingPage