import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../services/AuthProvider";
import LayoutPage from "../../layouts/base"
import { Button, InputGroup, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import React, { useEffect, useRef, useState } from "react";
import { faBuilding, faPieChart, faRemove } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "../../../constants/ApiConfig";
import { useNavigate, useParams } from "react-router-dom";
import { Chart } from "chart.js";
import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


function ReportBySubBlockPage() {

    const itemsPerPage = 5;
    const { blockId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [chartLoading, setChartLoading] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [blockData, setBlockData] = useState([]);
    const [subBlockData, setSubBlockData] = useState([]);
    const chartSubBlockCategory = useRef([]);
    const [showModal, setShowModal] = useState(false);
    const [subBlockId, setSubBlockId] = useState(null);
    const [eachSubBlock, setEachSubBlock] = useState({});

    const clearSearch = () => {
        setSearchTerm('');
    };

    const fetchBlockData = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}report/subBlock/${blockId}`);
            if (response.ok) {
                const data = await response.json();
                setBlockData(data.block);
                setSubBlockData(data.subBlocks);
                console.log(data.subBlocks);
                setIsLoading(false);

                chartSubBlockCategory.current = new Array(data.subBlocks.length).fill().map(() => React.createRef());
                // Populate chartRefs with refs for each block
                const newChartRefs = {};
                data.subBlocks.forEach(subBlock => {
                    newChartRefs[subBlock.id] = React.createRef();
                });
                chartSubBlockCategory.current = newChartRefs;
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchBlockData();
    }, [blockId]);

    // Function to export data to Excel
    const exportToExcel = () => {
        console.log(filteredSubBlocks);
        // Include index numbering and required columns in the data
        const dataWithIndex = filteredSubBlocks.map((subBlock, index) => ({
            '#': index + 1,
            'DIVISION': subBlock.block.division_name || '-',
            'BLOCK': subBlock.block_name || '-',
            'SUB-BLOCK': subBlock.name || '-',
            'TOTAL PLANTS': subBlock.total_plantations || '0',
            'PLANT TYPES': Object.keys(subBlock.plantTypes).length > 0 ?
                Object.keys(subBlock.plantTypes).map(type => `${type.trim() === '' ? 'Other' : type} (${subBlock.plantTypes[type]})`).join(', ') :
                'No plant types'
        }));
    
        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(dataWithIndex, {
            header: ['#', 'DIVISION', 'BLOCK', 'SUB-BLOCK', 'TOTAL PLANTS', 'PLANT TYPES'], // Define the header column titles
        });

        ws['!cols'] = [{ wch: 5 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 40 }];

        // Generate Excel file
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sub-Blocks");
        XLSX.writeFile(wb, "report_by_sub_block.xlsx");
    };  

    const filteredSubBlocks = subBlockData.filter(subBlock =>
        subBlock.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredSubBlocks.slice(indexOfFirstItem, indexOfLastItem);

    const renderIndex = (index) => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };

    const totalPages = Math.ceil(filteredSubBlocks.length / itemsPerPage);
    const maxPagesToShow = 5;

    // Change page
    const paginate = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) {
            return;
        }
        setCurrentPage(pageNumber);
    };

    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    const totalEntries = filteredSubBlocks.length;
    const startEntry = renderIndex(0);
    const endEntry = renderIndex(currentItems.length - 1);

    // Function to generate an array of page numbers to display
    const getPageNumbers = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, index) => start + index);
    };

    const handleShowChartModal = (subBlockId) => {
        setSubBlockId(subBlockId);
        setShowModal(true);
    };

    // Statistics of type chart
    const fetchCategoryData = async (subBlockId) => {
        try {
            setChartLoading(prevState => ({
                ...prevState,
                [subBlockId]: true
            }));

            const url = new URL(`${API_BASE_URL}report/subBlock/${blockId}`);
            url.searchParams.append('subBlockId', subBlockId);

            const response = await fetch(url);
            const data = await response.json();

            setEachSubBlock(data.eachSubBlock);

            const statistics = data.statistics; console.log(statistics)

            const labels = statistics.map(item => item.name);  console.log(labels)
            const values = statistics.map(item => item.total_plantations); console.log(values)

            const backgroundColors = generateRandomColors(labels.length);

            const chartData = {
                labels,
                datasets: [{
                    data: values,
                    backgroundColor: backgroundColors,
                }],
            };

            // Configuration options for the pie chart
            const options = {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                    },
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const value = context.formattedValue || '';
                                
                                return `${value} Plant`;
                            },
                        },
                    },
                },
            };

            // Get the context of the canvas element
            const canvas = chartSubBlockCategory.current[subBlockId]?.current; console.log(canvas)
            if (canvas) {
                const ctx = canvas.getContext('2d');

                // Continue with chart creation
                if (chartSubBlockCategory[subBlockId]?.current) {
                    chartSubBlockCategory[subBlockId]?.current.destroy();
                }

                // Create the pie chart
                const newChartCategory = new Chart(ctx, {
                    type: 'pie',
                    data: chartData,
                    options,
                });

                setChartLoading(prevState => ({
                    ...prevState,
                    [subBlockId]: false
                }));
            } else {
                console.error("Canvas element not found or not mounted.");
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        if(subBlockId) {
            fetchCategoryData(subBlockId);

            return () => {
                if (chartSubBlockCategory.current[subBlockId]?.chartInstance) {
                    chartSubBlockCategory.current[subBlockId].chartInstance.destroy();
                }
            };
        }
    }, [subBlockId]);

    // Function to generate random colors
    const generateRandomColors = (count) => {
        const backgroundColors = [];
        const hueStep = 360 / count; // Calculate the step for distributing hues evenly
        for (let i = 0; i < count; i++) {
            const hue = (hueStep * i) % 360; // Generate hue based on step
            const color = getRandomColor(hue);
            backgroundColors.push(color);
        }
        return backgroundColors;
    };

    // Function to generate a random color with specific hue
    const getRandomColor = (hue) => {
        const s = 80; // Fixed saturation for vibrant colors
        const l = 50; // Fixed lightness for consistent brightness

        return `hsl(${hue}, ${s}%, ${l}%)`;
    };

    const handleModalClose = () => {
        setShowModal(false);
        setSubBlockId(0);
    };

    const handlePrint = () => {
        const chartCanvas = chartSubBlockCategory.current[subBlockId].current;
        if (chartCanvas) {
            html2canvas(chartCanvas).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgWidth = 208;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.save(`chart_${subBlockId}.pdf`);
            });
        }
    };
    

    return (
        <LayoutPage>
            <div className="children">
                {isLoading && (
                    <div className="spinner-overlay">
                        <div className="spinner"></div>
                    </div>
                )}
                <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                    <h4 className="mt-3 pg_title">
                        REPORT BY SUB-BLOCK ({blockData.division_name} - Block {blockData.name})
                    </h4>
                </div>
                <div className="d-flex justify-content-between p-3">
                    <div>
                        <InputGroup>
                            <Form.Control
                                placeholder="Search..."
                                aria-label=""
                                aria-describedby="basic-addon1"
                                style={{ width: "200px" }}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                value={searchTerm}
                            />
                            {searchTerm && (
                                <InputGroup.Text id="clear-search" onClick={clearSearch}>
                                    <FontAwesomeIcon icon={faRemove} className="icon edit-icon" />
                                </InputGroup.Text>
                            )}
                        </InputGroup>
                    </div>
                    <div className='d-flex text-right'>
                        <button className='eksport_btn ml-2' onClick={exportToExcel}>
                            Export
                        </button>
                    </div>
                </div>
                <div className='m-3 table-container table_responsive'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Sub-Block</th>
                                <th>Total Plant</th>
                                <th>Clone</th>
                                <th className='text-center'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((subBlock, index) => (
                                    <tr key={index}>
                                        <td>{renderIndex(index)}</td>
                                        <td>{subBlock.name || '-'}</td>
                                        <td>{subBlock.total_plantations || '0'}</td>
                                        <td>
                                            {Object.keys(subBlock.plantTypes).length > 0 ? (
                                                Object.keys(subBlock.plantTypes).map((type, index) => (
                                                    <div key={index}>
                                                        {type.trim() === '' ? 'Other' : type} ({subBlock.plantTypes[type]})
                                                    </div>
                                                ))
                                            ) : (
                                                'No plant types'
                                            )}
                                        </td>
                                        <td className="text-center">
                                            <OverlayTrigger placement="top" overlay={<Tooltip>Clone Overview</Tooltip>} >
                                                <FontAwesomeIcon icon={faPieChart} className="icon chart-icon" onClick={() => handleShowChartModal(subBlock.id)}/>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className="text-center">No data found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex flex-wrap-reverse">
                    <div className='col-md-6'>
                        <p className='pl-3' style={{ fontFamily: 'Poppins' }}>
                            Showing {startEntry} to {endEntry} of {totalEntries} entries
                        </p>
                    </div>

                    {/* Pagination component */}
                    <div className='col-md-6 pagination flex-wrap'>
                        <div>
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <button onClick={() => paginate(currentPage - 1)} className="page-link">
                                    Previous
                                </button>
                            </li>
                        </div>
                        <div className='d-flex flex-wrap pr-1'>
                            {getPageNumbers(startPage, endPage).map((page) => (
                                <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                                    <button onClick={() => paginate(page)} className="page-link">
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </div>
                        <div >
                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                <button onClick={() => paginate(currentPage + 1)} className="page-link">
                                    Next
                                </button>
                            </li>
                        </div>
                    </div>
                </div>
            </div>

            <Modal className='modal_responsive' show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Clones Overview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className='card-title'>Sub-Block: {eachSubBlock.name}</h5>
                    <div className="my-4 row">
                        <div key={subBlockId}>
                            <div className="spinner-overlay" style={{ display: chartLoading[subBlockId] ? 'flex' : 'none' }}>
                                <div className="spinner"></div>
                            </div>
                            <canvas ref={chartSubBlockCategory.current[subBlockId]}></canvas>
                        </div>
                    </div>
                    <Button variant="primary" className="mt-4" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Body>
            </Modal>
        </LayoutPage>
    )
}

export default ReportBySubBlockPage