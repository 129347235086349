// import LayoutPage from './layouts/base';
import LayoutPage from '../layouts/base';
import { faPlus, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Modal } from 'react-bootstrap';
import SuccessModal from "../components/success-modal";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../constants/ApiConfig';
import { useAuth } from '../../services/AuthProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function RoleListPage() {

    const { user, hasPermission } = useAuth();

    const canManageRole = hasPermission('manage-role');
    const canCreateRole = hasPermission('create-role');
    const canEditRole = hasPermission('edit-role');
    const canDeleteRole = hasPermission('delete-role');

    const [roleData, setRoleData] = useState([]);
    const [permissionData, setPermissionData] = useState([]);
    const handleRoleModal = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);
    const [showModal, setShowModal] = useState(false);
    const [roleName, setRoleName] = useState('');
    const [checkedPermissions, setCheckedPermissions] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showSuccessDeleteModal, setshowSuccessDeleteModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [editRoleName, setEditRoleName] = useState('');
    const [editRoleId, setEditRoleId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const handleEditModalClose = () => setShowEditModal(false);
    const [editRoleData, setEditRoleData] = useState([]);

    useEffect(() => {
        const fetchRoleData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}role/index`);
                if (response.ok) {
                    const data = await response.json();
                    setRoleData(data.roles);
                    setPermissionData(data.permissions);
                }
            } catch (error) {
                console.error('Error fetching zone data:', error);
            }
        };

        fetchRoleData();
    }, []);

    const handleNameChange = (e) => {
        setRoleName(e.target.value);
    };

    const handlePermissionChange = (permissionId, checked) => {
        if (checked) {
            setCheckedPermissions([...checkedPermissions, permissionId]);
        } else {
            setCheckedPermissions(checkedPermissions.filter(permission => permission !== permissionId));
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}role/store`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    roleName: roleName,
                    rolePermissions: checkedPermissions,
                }),
            });

            if (response.status == 200) {
                setShowModal(false);
                setShowSuccessModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    }

    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    const handleDeleteRole = async (index) => {
        try {
            const response = await fetch(`${API_BASE_URL}role/destroy/${index}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status == 200) {
                setShowDeleteModal(false);
                setshowSuccessDeleteModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const handleConfirmDelete = (id) => {
        setDeleteIndex(id);
        setShowDeleteModal(true);
    };

    const handleEditRoleModal = (roleId) => {
        setEditRoleId(roleId);
        setShowEditModal(true);
    };

    // Function to fetch role data by ID
    const fetchRoleById = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}role/edit/${id}`);
            if (response.ok) {
                const data = await response.json();

                const role = data.role;
                setEditRoleData(role);
                setEditRoleName(role.name)
            }
        } catch (error) {
            console.error('Error fetching zone:', error);
        }
    };

    useEffect(() => {
        if (editRoleId !== null) {
            fetchRoleById(editRoleId);
        }
    }, [editRoleId]);

    const handleEditNameChange = (event) => {
        setEditRoleName(event.target.value);
    };

    const handleUpdatePermissionChange = (permissionId, checked) => {
        console.log('Before:', editRoleData.permissions);

        setEditRoleData(prevData => {
            const updatedPermissions = checked
                ? [...prevData.permissions, { id: permissionId }]
                : prevData.permissions.filter(permission => permission.id !== permissionId);

            console.log('After:', updatedPermissions);

            return { ...prevData, permissions: updatedPermissions };
        });
    }

    const handleUpdateRole = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}role/update/${editRoleId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    roleName: editRoleName,
                    rolePermissions: editRoleData.permissions.map(permission => permission.id),
                }),
            });

            if (response.ok) {
                setShowEditModal(false);
                setShowSuccessModal(true);
            }
        } catch (error) {
            // Handle fetch errors
            console.error('Error updating zone:', error);
        }
    }

    return (
        <LayoutPage>
            <div className='children'>
                <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                    <h4 className=" pg_title">
                        ROLES LIST
                    </h4>
                </div>
                {canCreateRole && <div className="addDisease mt-3 mx-3 d-flex justify-content-center mb-4" onClick={handleRoleModal}>
                    <div className="icon">
                        <FontAwesomeIcon className="icon" icon={faPlus} />
                    </div>
                    <div className="text">
                        Role
                    </div>
                </div>}
                <div>
                    <div className='m-3 table-container table_responsive'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Name</th>
                                    {(canEditRole || canDeleteRole) && <th className='text-center'>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {roleData.length > 0 ? (
                                    roleData.map((role, index) => (
                                        <tr key={role.id}>
                                            <td>{index + 1}</td>
                                            <td>{role.name}</td>
                                            {(canEditRole || canDeleteRole) && <td className='text-center'>
                                                <div className="unwrap editdelete_icon">
                                                    {canEditRole &&
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                                            <FontAwesomeIcon icon={faPencil} className="icon edit-icon" onClick={() => handleEditRoleModal(role.id)} />
                                                        </OverlayTrigger>
                                                    }
                                                    {canDeleteRole &&
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>} >
                                                            <FontAwesomeIcon icon={faTrash} className="icon delete-icon" onClick={() => handleConfirmDelete(role.id)} />
                                                        </OverlayTrigger>
                                                    }
                                                </div>
                                            </td>}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" className="text-center">No data found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            {/* Modal Create Role */}
            <Modal className='modal_responsive' show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left">Name</Form.Label>
                            <Form.Control value={roleName} onChange={handleNameChange} type="text" placeholder="Enter role name" required />
                        </Form.Group>
                        <Form.Group controlId="" className=''>
                            <Form.Label className="label-left mt-3">Role Permissions</Form.Label>
                            <div className='row ml-0'>
                                {permissionData.map((permission, index) => {

                                    const formattedLabel = permission.name.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

                                    return (
                                        <Form.Check
                                            key={index}
                                            className={`col-md-6 col-6 mb-2 label-left`}
                                            label={formattedLabel}
                                            name={`group${index + 1}`}
                                            onChange={(e) => handlePermissionChange(permission.id, e.target.checked)}
                                        />
                                    );
                                })}
                            </div>
                        </Form.Group>

                        <Button variant="primary" type="submit" className="mt-4">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal Edit Role */}
            <Modal className='modal_responsive' show={showEditModal} onHide={handleEditModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Update Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleUpdateRole}>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left">Name</Form.Label>
                            <Form.Control value={editRoleName} onChange={handleEditNameChange} type="text" placeholder="Enter role name" required />
                        </Form.Group>
                        <Form.Group controlId="" className=''>
                            <Form.Label className="label-left mt-3">Role Permissions</Form.Label>
                            <div className='row ml-0'>
                                {permissionData.map((permission, index) => {

                                    const formattedLabel = permission.name.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

                                    return (
                                        <Form.Check
                                            key={index}
                                            className={`col-md-6 col-6 mb-2 label-left`}
                                            label={formattedLabel}
                                            name={`group${index + 1}`}
                                            checked={editRoleData.permissions && editRoleData.permissions.some(rolePermission => rolePermission.id === permission.id)}
                                            onChange={(e) => handleUpdatePermissionChange(permission.id, e.target.checked)}
                                        />
                                    );
                                })}
                            </div>
                        </Form.Group>

                        <Button variant="primary" type="submit" className="mt-4">
                            Update
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal/Popup for Success Message */}
            <SuccessModal
                className='modal_responsive'
                show={showSuccessModal}
                onHide={closeSuccessModal}
                title="Success"
                modalBody={<p>Data successfully saved!</p>}
                buttonText="Close"
            />

            {/* Delete Modal */}
            <Modal className='modal_responsive' show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this role?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button className="delete-button" onClick={() => handleDeleteRole(deleteIndex)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal/Popup for Confirmation Delete */}
            <SuccessModal
                className='modal_responsive'
                show={showSuccessDeleteModal}
                onHide={closeSuccessModal}
                title="Role Deleted"
                modalBody={<p>Data was successfully deleted!</p>}
                buttonText="OK"
            />

        </LayoutPage>
    )
}

export default RoleListPage