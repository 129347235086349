import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../services/AuthProvider";
import LayoutPage from "../../layouts/base";
import { InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import React, { useEffect, useRef, useState } from "react";
import { faBuilding, faEye, faRemove } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "../../../constants/ApiConfig";
import { useNavigate, useParams } from "react-router-dom";
import { Chart } from "chart.js";
import * as XLSX from 'xlsx';

function ReportByPhasePage() {

    const itemsPerPage = 10;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const { user, hasPermission } = useAuth();
    const canManageReport = hasPermission('manage-report');

    const { divisionId } = useParams();

    const [searchTerm, setSearchTerm] = useState('');

    const clearSearch = () => {
        setSearchTerm('');
    };

    const [phaseData, setPhaseData] = useState([]);

    useEffect(() => {
        const fetchPhaseData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}report/phase`);
                if (response.ok) {
                    const data = await response.json();
                    console.log(data)
                    setPhaseData(data.phases);

                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPhaseData();
    }, []);

    const filteredPhases = phaseData.filter(phase =>
        phase.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredPhases.slice(indexOfFirstItem, indexOfLastItem);

    const renderIndex = (index) => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };

    const totalPages = Math.ceil(filteredPhases.length / itemsPerPage);
    const maxPagesToShow = 5;

    // Change page
    const paginate = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) {
            return;
        }
        setCurrentPage(pageNumber);
    };

    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    const totalEntries = filteredPhases.length;
    const startEntry = renderIndex(0);
    const endEntry = renderIndex(currentItems.length - 1);

    // Function to generate an array of page numbers to display
    const getPageNumbers = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, index) => start + index);
    };

    // Function to export data to Excel
    const exportToExcel = () => {
        console.log(filteredPhases);
        // Include index numbering and required columns in the data
        const dataWithIndex = filteredPhases.map((phase, index) => ({
            '#': index + 1,
            'PHASE': phase.name || '-',
            'TOTAL PLANTS': phase.plantations.length || '0',
        }));
    
        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(dataWithIndex, {
            header: ['#', 'PHASE', 'TOTAL PLANTS'], // Define the header column titles
        });

        ws['!cols'] = [{ wch: 5 }, { wch: 15 }, { wch: 15 }];
        
        // Generate Excel file
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Phases");
        XLSX.writeFile(wb, "report_by_phase.xlsx");
    }; 


    return (
        <LayoutPage>
            <div className="children">
                {isLoading && (
                    <div className="spinner-overlay">
                        <div className="spinner"></div>
                    </div>
                )}
                <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                    <h4 className="mt-3 pg_title">
                        REPORT BY PHASE
                    </h4>
                </div>
                <div className="d-flex justify-content-between p-3">
                    <div>
                        <InputGroup>
                            <Form.Control
                                placeholder="Search..."
                                aria-label=""
                                aria-describedby="basic-addon1"
                                style={{ width: "200px" }}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                value={searchTerm}
                            />
                            {searchTerm && (
                                <InputGroup.Text id="clear-search" onClick={clearSearch}>
                                    <FontAwesomeIcon icon={faRemove} className="icon edit-icon" />
                                </InputGroup.Text>
                            )}
                        </InputGroup>
                    </div>
                    <div className='d-flex text-right'>
                        <button className='eksport_btn ml-2' onClick={exportToExcel}>
                            Export
                        </button>
                    </div>
                </div>
                <div className='m-3 table-container table_responsive'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Phase</th>
                                <th className="text-center">Total Plant</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((phase, index) => (
                                    <tr key={index}>
                                        <td>{renderIndex(index)}</td>
                                        <td>{phase.name || 'Others'}</td>
                                        <td className="text-center">{phase.plantations.length || '0'}</td>
                                        {(canManageReport) && <td className='text-center'>
                                            <div className="unwrap editdelete_icon">
                                                {canManageReport &&
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>} >
                                                        <FontAwesomeIcon
                                                            icon={faEye}
                                                            className="icon edit-icon"
                                                            onClick={() => navigate(`/report/phase/${phase.id}`)}
                                                        /></OverlayTrigger>}
                                            </div>
                                        </td>}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="text-center">No data found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex flex-wrap-reverse">
                    <div className='col-md-6'>
                        <p className='pl-3' style={{ fontFamily: 'Poppins' }}>
                            Showing {startEntry} to {endEntry} of {totalEntries} entries
                        </p>
                    </div>

                    {/* Pagination component */}
                    <div className='col-md-6 pagination flex-wrap'>
                        <div>
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <button onClick={() => paginate(currentPage - 1)} className="page-link">
                                    Previous
                                </button>
                            </li>
                        </div>
                        <div className='d-flex flex-wrap pr-1'>
                            {getPageNumbers(startPage, endPage).map((page) => (
                                <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                                    <button onClick={() => paginate(page)} className="page-link">
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </div>
                        <div >
                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                <button onClick={() => paginate(currentPage + 1)} className="page-link">
                                    Next
                                </button>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutPage>
    )
}

export default ReportByPhasePage
