import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function SuccessModal(props) {
  const { onHide, title, modalBody, buttonText } = props;

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalBody}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button onClick={onHide}>{buttonText}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SuccessModal;
