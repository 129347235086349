// import LayoutPage from './layouts/base';
import LayoutPage from '../layouts/base';
import { faPlus, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Modal } from 'react-bootstrap';
import SuccessModal from "../components/success-modal";
import { useEffect, useState } from 'react';
import { API_BASE_URL, IMAGE_BASE_URL } from '../../constants/ApiConfig';
import { useAuth } from '../../services/AuthProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


function ManageUserPage() {

    const itemsPerPage = 10;

    const { user, hasPermission } = useAuth();

    const canManageUser = hasPermission('manage-user');
    const canCreateUser = hasPermission('create-user');
    const canEditUser = hasPermission('edit-user');
    const canDeleteUser = hasPermission('delete-user');

    const handleUserModal = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [roleData, setRoleData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [showSuccessDeleteModal, setshowSuccessDeleteModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [editUserId, setEditUserId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const handleEditModalClose = () => setShowEditModal(false);
    const [editUserData, setEditUserData] = useState([]);
    const [editFormData, setEditFormData] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNo: '',
        role: '',
        image: '',
    });



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImageChange = (e) => {
        setFormData({
            ...formData,
            image: e.target.files[0],
        })
    };

    useEffect(() => {
        const fetchRoleData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}role/index`);
                if (response.ok) {
                    const data = await response.json();

                    setRoleData(data.roles);
                }
            } catch (error) {
                console.error('Error fetching role data:', error);
            }
        };

        const fetchUserData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}user/index`);
                if (response.ok) {
                    const data = await response.json();
                    setUserData(data.users)
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
        fetchRoleData();
    }, []);

    const handleSubmitUser = async (e) => {
        e.preventDefault();

        try {
            const formDataObject = new FormData();

            // Append form fields to formDataObject
            formDataObject.append('name', formData.name);
            formDataObject.append('email', formData.email);
            formDataObject.append('phoneNo', formData.phoneNo);
            formDataObject.append('role', formData.role);

            // Check if image exists before appending
            if (formData.image) {
                formDataObject.append('image', formData.image);
            }

            const response = await fetch(`${API_BASE_URL}user/store`, {
                method: 'POST',
                body: formDataObject,
            });

            if (response.status == 200) {
                setShowModal(false);
                setShowSuccessModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    }

    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    // Pagination Start
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = userData.slice(indexOfFirstItem, indexOfLastItem);

    const renderIndex = (index) => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };
    // Pagination end

    const handleDeleteUser = async (index) => {
        try {
            const response = await fetch(`${API_BASE_URL}user/destroy/${index}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status == 200) {
                setShowDeleteModal(false);
                setshowSuccessDeleteModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const handleConfirmDelete = (id) => {
        setDeleteIndex(id);
        setShowDeleteModal(true);
    };

    const handleEditUserModal = (userId) => {
        setEditUserId(userId);
        setShowEditModal(true);
    };

    const fetchUserById = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}user/edit/${id}`);
            if (response.ok) {
                const data = await response.json();

                const user = data.user;

                setEditUserData((prevUserData) => ({
                    ...prevUserData,
                    ...user,
                }));

                setEditFormData((prevFormData) => ({
                    ...prevFormData,
                    name: user.name || '',
                    email: user.email || '',
                    phoneNo: user.phone_number || '',
                    image: user.image || '',
                    role: user.role_id || '',
                }));
            }
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    };

    useEffect(() => {
        if (editUserId !== null) {
            fetchUserById(editUserId);
        }
    }, [editUserId]);

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditFormData({
            ...editFormData,
            [name]: value,
        });
    };

    const handleEditImageChange = (e) => {
        setEditFormData({
            ...editFormData,
            image: e.target.files[0],
        })
    };

    const handleUpdateUser = async (e) => {
        e.preventDefault();

        try {
            const formDataObject = new FormData();

            formDataObject.append('name', editFormData.name);
            formDataObject.append('email', editFormData.email);
            formDataObject.append('phoneNo', editFormData.phoneNo);
            formDataObject.append('role', editFormData.role);

            if (editFormData.image) {
                formDataObject.append('image', editFormData.image);
            }

            const response = await fetch(`${API_BASE_URL}user/update/${editUserId}`, {
                method: 'POST',
                body: formDataObject,
            });

            if (response.ok) {
                setShowEditModal(false);
                setShowSuccessModal(true);
            }
        } catch (error) {
            // Handle fetch errors
            console.error('Error updating user:', error);
        }
    };

    return (
        <LayoutPage>
            <div className='children'>
                <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                    <h4 className=" pg_title">
                        Users List
                    </h4>
                </div>
                {canCreateUser &&
                    <div className="addDisease mt-3 mx-3 d-flex justify-content-center mb-4" onClick={handleUserModal}>
                        <div className="icon">
                            <FontAwesomeIcon className="icon" icon={faPlus} />
                        </div>
                        <div className="text">
                            User
                        </div>
                    </div>
                }

                <div>
                    <div className='m-3 table-container table_responsive'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Role</th>
                                    {(canEditUser || canDeleteUser) && <th className='text-center'>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.length > 0 ? (
                                    currentItems.map((user, index) => (
                                        <tr key={index}>
                                            <td>{renderIndex(index)}</td>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.phone_number}</td>
                                            <td>{user.role_name}</td>
                                            {(canEditUser || canDeleteUser) &&
                                                <td className='text-center'>
                                                    <div className="unwrap editdelete_icon">
                                                        {canEditUser &&
                                                            <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
                                                                <FontAwesomeIcon icon={faPencil} className="icon edit-icon" onClick={() => handleEditUserModal(user.id)} />
                                                            </OverlayTrigger>
                                                        }
                                                        {canDeleteUser &&
                                                            <OverlayTrigger placement='top' overlay={<Tooltip>Delete</Tooltip>}>
                                                                <FontAwesomeIcon icon={faTrash} className="icon delete-icon" onClick={() => handleConfirmDelete(user.id)} />
                                                            </OverlayTrigger>
                                                        }
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" className="text-center">No data found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                    <div className='pagination'>
                        <button onClick={handlePrevPage} disabled={currentPage === 1}>
                            Prev
                        </button>
                        <button onClick={handleNextPage} disabled={indexOfLastItem >= userData.length}>
                            Next
                        </button>
                    </div>
                </div>
            </div>


            {/* Modal Create User */}
            <Modal className='modal_responsive' show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add New User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Your form content */}
                    <Form onSubmit={handleSubmitUser}>
                        <Form.Group controlId="">
                            <Form.Label className="label-left mt-3">Profile Image</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleImageChange}
                                accept="image/*"
                            />
                        </Form.Group>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">Name</Form.Label>
                            <Form.Control value={formData.name} onChange={handleInputChange} name='name' type="text" placeholder="Enter name" required />
                        </Form.Group>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">Email</Form.Label>
                            <Form.Control value={formData.email} onChange={handleInputChange} name='email' type="email" placeholder="Enter email" required />
                        </Form.Group>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">Phone Number</Form.Label>
                            <Form.Control value={formData.phoneNo} onChange={handleInputChange} name='phoneNo' type="text" placeholder="Enter phone number" />
                        </Form.Group>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">Role</Form.Label>
                            <Form.Control
                                as="select"
                                value={formData.role}
                                onChange={handleInputChange}
                                name="role"
                            >
                                <option value="">Select Role</option>
                                {roleData.map((option, optionIndex) => (
                                    <option key={optionIndex} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal/Popup for Success Message */}
            <SuccessModal className='modal_responsive'
                show={showSuccessModal}
                onHide={closeSuccessModal}
                title="Success"
                modalBody={<p>Data successfully saved!</p>}
                buttonText="Close"
            />

            {/* Modal Edit User */}
            <Modal className='modal_responsive' show={showEditModal} onHide={handleEditModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Update User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Your form content */}
                    <Form onSubmit={handleUpdateUser}>
                        {editUserData.image && (
                            <div className="rounded-image-preview">
                                <img
                                    src={`${IMAGE_BASE_URL}${editUserData.image}`}
                                    alt="Preview"
                                    className='preview-profile'
                                />
                            </div>
                        )}
                        <Form.Group controlId="">
                            <Form.Label className="label-left mt-3">Profile Image</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleEditImageChange}
                                accept="image/*"
                            />
                        </Form.Group>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">Name</Form.Label>
                            <Form.Control value={editFormData.name} onChange={handleEditInputChange} name='name' type="text" placeholder="Enter name" required />
                        </Form.Group>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">Email</Form.Label>
                            <Form.Control value={editFormData.email} onChange={handleEditInputChange} name='email' type="email" placeholder="Enter email" required />
                        </Form.Group>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">Phone Number</Form.Label>
                            <Form.Control value={editFormData.phoneNo} onChange={handleEditInputChange} name='phoneNo' type="text" placeholder="Enter phone number" />
                        </Form.Group>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">Role</Form.Label>
                            <Form.Control
                                as="select"
                                value={editFormData.role}
                                onChange={handleEditInputChange}
                                name="role"
                            >
                                <option value="">Select Role</option>
                                {roleData.map((option, optionIndex) => (
                                    <option key={optionIndex} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Update
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Delete Modal */}
            <Modal className='modal_responsive' show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this user?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button className="delete-button" onClick={() => handleDeleteUser(deleteIndex)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal/Popup for Confirmation Delete */}
            <SuccessModal className='modal_responsive'
                show={showSuccessDeleteModal}
                onHide={closeSuccessModal}
                title="User Deleted"
                modalBody={<p>Data was successfully deleted!</p>}
                buttonText="OK"
            />
        </LayoutPage>
    )
}

export default ManageUserPage