// import LayoutPage from './Base';
import { useEffect, useRef, useState } from 'react';
import LayoutPage from './layouts/base';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import { API_BASE_URL, SABAHEBAT_DEV_URL } from '../constants/ApiConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCross, faRemove } from '@fortawesome/free-solid-svg-icons';
import { Chart } from 'chart.js/auto';
import { useAuth } from '../services/AuthProvider';
import { Button, Modal } from 'react-bootstrap';
import SuccessModal from './components/success-modal';
import { useNavigate } from 'react-router-dom';
import OpenLayersMap from "../components/map/map";


function Dashboard() {

    const navigate = useNavigate();
    const { user, hasPermission } = useAuth();

    const [showPwdModal, setShowPwdModal] = useState(false);
    const [password, setPassword] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                if (user) {
                    if (user.is_login == 0) {
                        setShowPwdModal(true);
                    }
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [user]);
   
    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmitPassword = async (e) => {
        e.preventDefault();

        const userId = user.id;

        try {
            const response = await fetch(`${API_BASE_URL}user/updatePassword/${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newPassword: password }),
            });

            if (response.status == 200) {
                setShowPwdModal(false);
                setShowSuccessModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    }


    return (
        <LayoutPage>
            <div className='children'>
                <h4 className='bold_poppins mt-4 mb-3 pg_title'>Dashboard</h4>
                <div>
                    <div className='card mb-5'>
                        <div className='card-body'>
                            <div className="App">
                                <OpenLayersMap />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal/Popup for Success Message */}
            <SuccessModal
                show={showSuccessModal}
                onHide={closeSuccessModal}
                title="Successful"
                modalBody={<p>Successfully updating your password!</p>}
                buttonText="Close"
            />

            {/* Modal Update User Password */}
            <Modal show={showPwdModal} centered>
                <Modal.Header>
                    <Modal.Title>Change Your Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ textAlign: 'justify', fontWeight: 'bold' }}>For security reasons, we require you to change your password on your first login.</p>
                    {/* Your form content */}
                    <Form onSubmit={handleSubmitPassword}>
                        <Form.Group controlId="formTamanName" className="mt-4">
                            <Form.Label className="label-left">Password</Form.Label>
                            <Form.Control value={password} onChange={handlePasswordChange} type="password" placeholder="Enter new password" required />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Update
                        </Button>

                    </Form>
                </Modal.Body>
            </Modal>

        </LayoutPage>
    )
}

export default Dashboard