import { API_BASE_URL } from '../constants/ApiConfig';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { setAuthData } from '../services/auth';

function LoginPage() {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [redirectViewUrl, setRedirectViewUrl] = useState('');

    const queryParams = new URLSearchParams(window.location.search);
    const encodedRedirectViewUrl = queryParams.get('redirectViewUrl');
    const encodedRedirectUrl = queryParams.get('redirectUrl')

    useEffect(() => {
        if(encodedRedirectViewUrl || encodedRedirectUrl) {
            const redirectViewUrl = decodeURIComponent(encodedRedirectViewUrl);
            localStorage.setItem('redirectViewUrl', redirectViewUrl);
            setRedirectViewUrl(redirectViewUrl);

            const redirectUrl = decodeURIComponent(encodedRedirectUrl);
            localStorage.setItem('redirectUrl', redirectUrl);
        } 
    }, [encodedRedirectUrl, encodedRedirectViewUrl]);

    useEffect(() => {
        // Check authentication status when the component mounts
        const TOKEN_KEY = 'auth_token';
        if (localStorage.getItem(TOKEN_KEY)) {
            // If authenticated, redirect to the dashboard
            navigate('/dashboard');
        }
    }, [navigate]);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (response.ok) {
                const { token, userId } = data;
                setAuthData(token, userId);

                const queryParams = new URLSearchParams(window.location.search);
                const encodedRedirectUrl = queryParams.get('redirectUrl')

                if(encodedRedirectUrl) {
                    const redirectUrl = decodeURIComponent(encodedRedirectUrl);
                    localStorage.setItem('redirectUrl', redirectUrl);
                    window.location.href = redirectUrl;
                } else {
                    navigate('/dashboard');
                }
            } else {
                setError(data.message || 'Login failed.');
                setEmail('');
                setPassword('');
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
            setError('An error occurred during login.');
        }
    };

    return (
        <div className='login_layout'>
            <div className='card card_layout'>
                <div className='row'>
                    <div className='col-md-6 col-sm-12 login_leftCard'>
                        <img
                            src="/images/login_img.png"
                            className="img-fluid rounded-start login_img"
                            alt="..."
                        />
                    </div>
                    <div className='col-md-6 col-sm-12 p-3'>
                        <fieldset className='mt-4 mr-4'>
                            <legend className='regular_poppins leg1'>Login</legend>
                            <div className='text-center mt-3 mb-3'>
                                <img
                                    src="/images/main_logo.png"
                                    className="img-fluid rounded-start main_logo"
                                    alt="..."
                                />
                            </div>

                            <div>
                                <form onSubmit={handleLogin}>
                                    <p className='login_label mt-5'>Account Login</p>
                                    <InputGroup className="mb-3 login_input" >
                                        <InputGroup.Text className="login_input" id="basic-addon1">@</InputGroup.Text>
                                        <Form.Control
                                            className='login_input'
                                            placeholder="Email"
                                            aria-label="Email"
                                            aria-describedby="basic-addon1"
                                            value={email}
                                            onChange={handleEmailChange}
                                        />
                                    </InputGroup>

                                    <InputGroup className="mb-4 login_input">
                                        <InputGroup.Text className="login_input" id="basic-addon1"> <FontAwesomeIcon icon={faLock} /></InputGroup.Text>
                                        <Form.Control
                                            className='login_input'
                                            type="password"
                                            placeholder="Password"
                                            aria-label="Password"
                                            aria-describedby="basic-addon2"
                                            value={password}
                                            onChange={handlePasswordChange}
                                        />
                                    </InputGroup>
                                    {error && (
                                        <div className="error-message mb-4" style={{ color: 'red' }}>
                                            {error}
                                        </div>
                                    )}
                                    <div className='text-center mt-5'>
                                        <Button type="submit" className="col-md-12 login_btn " variant="success">Login</Button>
                                    </div>
                                </form>
                            </div>
                            {encodedRedirectViewUrl && (
                            <div className="login_label text-center mt-3">
                                Are you a visitor?&nbsp;
                                <a href={redirectViewUrl} style={{ textDecoration: 'none', display: 'inline-block' }}>
                                Click here!
                                </a>
                            </div>
                            )}
                            <div className='text-center mb-0 mt-4'>
                                <p className='copyright'>2023 - All rights reserved</p>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default LoginPage